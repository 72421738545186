.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    display: flex;
}
.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}
.hero-container2 {
    min-height: 800px;
    max-height: 1000px;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
   
    object-fit: contain;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
    z-index: 1;
    border-radius: 0 0  80px 80px; 
    overflow: hidden;
} 

.hero-container2 > h1 {
    color: #E34B4B;
    font-size: 60px;
    padding-left: 75px;
    text-align: center; 
    display: flex;
}

.hero-container2 > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
    display: flex;
}

.hero-container2 > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-left: 5%;
    margin-top: 3%;
    padding-right: 25%;
}

.hero-btns2 {
    margin-top: -10%; /* Adjust the distance from the top as needed */
    margin-left: 90%; /* Adjust the distance from the left as needed */
    width: 100px; /* Adjust the width and height to make the button a circle */
    height: 100px; /* Adjust the width and height to make the button a circle */
    border-radius: 10%; /* Set border-radius to 50% to make it a circle */
    float: right;
    z-index: 1;
}

.btns {
    border-radius: 50px; /* Adjust the value to make the button completely round */
    float: right;
    margin-right: 20px; /* Adjust the margin as needed */
}/* Add these styles to your existing stylesheet */

.scrollable-images {
    width: 100%;
    object-fit: cover;
    height: 100%;
    position: relative;
    z-index: 0;
    margin-left: -30%;
    border-radius: inherit;
    



}

.image-container {
    display: none;
   width: 100%;
   height: 100%;
    position: absolute;
    z-index: 0;
    
   
}

.image-container.active {
    display: block;
}

.image-container img {
    width: 100%;
    height: 100%;
    position: relative;
}

.text-container {
    position: absolute;
    bottom: 10%; /* Adjust vertical position as needed */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    z-index: 1;
}

.text-container h1 {
    font-size: 66px;
    margin-bottom: 1rem;
    z-index: 1;
    color: #E34B4B;
    position: absolute;
    margin-left: 30%;
    margin-top: -10%;
   
  
    
}

.text-container p {
    font-size: 24px;
    margin-bottom: 50%;
    margin-left: 30%;
    text-align: left;
    z-index: 1;
    margin-right: -60%;
    margin-top: 10%;
   position: relative;

}

/* Animation for text scrolling */
.text-container h1, .text-container p {
    
    z-index: 1;

}

@keyframes scrollText {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
}
.scrollable-images > h1 {
    color: #E34B4B;
    font-size: 60px;
    padding-left: 75px;
    text-align: center; 
    z-index: 1;
}

.scrollable-images > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.scrollable-images > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-left: 5%;
    margin-bottom: 33%;
    padding-right: 25%;
}
.scroll-dots-container {
    position: absolute;
    bottom: 30%;
    left: 80%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.scroll-dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 5px 5px;
    cursor: pointer; 
}

.scroll-dot.active {
    background-color: red; /* Style the active dot to be red */
}

@media screen and (max-width: 430px) {

    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
        display: flex;
    }
    .homecs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    .hero-container2 {
    
        height: 50vh;
        
        width: 100%;
       
        padding: 0px;
       
        object-fit: contain;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
        z-index: 1;
        border-radius: 0 0  0px 0px; 
        overflow: hidden;
    } 
    
    .hero-container2 > h1 {
        color: #E34B4B;
        font-size: 30px;
        padding-left: 75px;
        text-align: center; 
        display: flex;
    }
    
    .hero-container2 > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
        display: flex;
    }
    
    .hero-container2 > p {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
        margin: 0 auto; 
        margin-left: 5%;
        margin-top: 3%;
        padding-right: 25%;
    }
    
    .hero-btns2 {
        margin-top: -10%; /* Adjust the distance from the top as needed */
        margin-left: 80%; /* Adjust the distance from the left as needed */
        width: 60px; /* Adjust the width and height to make the button a circle */
        height: 100px; /* Adjust the width and height to make the button a circle */
        border-radius: 10%; /* Set border-radius to 50% to make it a circle */
        float: right;
        z-index: 1;
        display: none;
    }
    
    .btns {
        border-radius: 50px; /* Adjust the value to make the button completely round */
        float: right;
        margin-right: 20px; /* Adjust the margin as needed */
        width: 50px;
        height: 50px;
    }/* Add these styles to your existing stylesheet */
    
    .scrollable-images {
        width: 100%;
        object-fit: cover;
        height: 100%;
        position: relative;
        z-index: 0;
        margin-left: -30%;
      
        border-radius: inherit;
        
    
    
    
    }
    
    .image-container {
        display: none;
       width: 100%;
       height: 70vh;
        position: absolute;
        z-index: 0;
        
       
    }
    .image-container > h1 {
        font-size: 30px;;
    }
    
    .image-container.active {
        display: block;
    }
    
    .image-container img {
        width: 100%;
        height: 80vh;
        position: relative;
    }
    
    .text-container {
        position: absolute;
        bottom: 10%; /* Adjust vertical position as needed */
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #fff;
        z-index: 1;
    }
    
    .text-container h1 {
        font-size: 1.5rem;
        margin-bottom: 0rem;
        z-index: 1;
        color: #E34B4B;
        position: absolute;
        margin-left: 30%;
        margin-top: 0%;
       
       
      
        
    }
    
    .text-container p {
        font-size: 1rem;
        margin-bottom: 50%;
        margin-left: 30%;
        text-align: left;
        z-index: 1;
        margin-right: -60%;
        margin-top: 20%;
       position: relative;
    
    }
    
    /* Animation for text scrolling */
    .text-container h1, .text-container p {
        
        z-index: 1;
    
    }
    
    @keyframes scrollText {
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(-100%);
        }
    }
    .scrollable-images > h1 {
        color: #E34B4B;
        font-size: 30px;
        padding-left: 75px;
        text-align: center; 
        z-index: 1;
    }
    
    .scrollable-images > h2 {
        color: #FFF;
        font-size: 30px;
        text-align: left; 
    }
    
    .scrollable-images > p {
        color: #FFF;
        font-size: 16px;
        text-align: left; 
        margin: 0 auto; 
        margin-left: 5%;
        margin-bottom: 33%;
        padding-right: 25%;
    }
    .scroll-dots-container {
        position: absolute;
        bottom: 20%;
        left: 80%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
    }
    
    .scroll-dot {
        width: 10px;
        height: 10px;
        background-color: white;
        border-radius: 50%;
        margin: 5px 5px;
        cursor: pointer; 
    }
    
    .scroll-dot.active {
        background-color: red; /* Style the active dot to be red */
    }


}


