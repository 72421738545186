.GovtB-container {
    background: #555555;

    height: 50vh;
    max-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
  }
  
  .Govtcontent-wrapperB {
    background-color: #3e3e3e;
    padding: 50px;
    border-radius: 50px;
    width: 80%;
    height: 60%;
    max-height: 300px;
    margin: 5% auto 0;
    display: flex;
    justify-content: space-between;
  }
  
  .GovtB-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px; /* Adjust padding to reduce gap */
    box-sizing: border-box;
  }
  
  .GovtB-subtitle {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .GovtB-learn-more {
    color: #E34B4B;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  
  .GovtB-learn-more i {
    margin-left: 5px;
  }
  
  .GovtB-section:not(:last-child) {
    border-right: 1px solid #fff;
    margin-right: 2%; /* Remove any extra margin */
  }

  .GovtB-container  img{
    width:150px;
    height: 150px;
  }

  

  @media screen and (max-width: 430px) { 
    .GovtB-container {
      background: #555555;
  
      height: auto;
      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      overflow: hidden;
      max-width: 100%; /* Set a max-width to match the navbar */
      margin: 0 auto; /* Center the hero container */
    }
    
    .Govtcontent-wrapperB {
      background-color: #3e3e3e;
      padding: 10px;
      border-radius: 20px;
      width: 100%;
      height: auto;
      margin: 5% auto 0;
      display: flex;
      justify-content: space-between;
    }
    
    .GovtB-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 0px; /* Adjust padding to reduce gap */
      box-sizing: border-box;
    }
    .GovtB-section > p {
      margin-left: 1%;
      text-align: left;
      font-size: 12px;
     
    
    }
    
    .GovtB-subtitle {
      color: white;
      font-size: 0.8rem;
      margin-bottom: 20px;
      text-align: center;
    }
    
    .GovtB-learn-more {
      color: #E34B4B;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }
    
    .GovtB-learn-more i {
      margin-left: 5px;
    }
    
    .GovtB-section:not(:last-child) {
      border-right: 1px solid #fff;
      margin-right: 2%; /* Remove any extra margin */
    }


  .GovtB-container  img{
    width:50px;
    height: 50px;
  }

   }
  
  