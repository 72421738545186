
.imageF {
    float: left;
    margin-right: 100px;

}


.homeF-container {
    background: #555555;
    min-height: 800px;
  
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative; /* Necessary for absolute positioning of rings */
    overflow: hidden;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.homeFs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.homeF__title {
    color: #fff;
    text-align: center;
}

.homeF > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.homeF__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 80%;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.homeF__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.homeF__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeF__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeF__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeF__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.homeF__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.homeF__item__link {
    width: 100%;
}

.homeF__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.homeF__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.homeF__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.homeF__item__img:hover {
    transform: scale(1.1);
}

.homeF__item__info {
    padding: 20px 30px 30px;
}

.homeF__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}
  
.homeF-container > h1 {
    color: #E34B4B;
    font-size: 48px;
    margin: 0 auto; 
    margin-right: 30%;
}

.homeF-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.homeF-container > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.homeF-container > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.homeF-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.homeF-container img {
    width: 100%; /* Set width to 500px */
    height: auto; /* Set height to 500px */
    border-radius: 0%; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: -55%;
    margin-right: 0px; /* Add margin for spacing */
  
    overflow: hidden;
    margin-bottom: 8%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0); /* Add slight shadow */
    z-index: 0;
  
  
    
    
}

.text-wrapperF {
    /* Styles for the text wrapper */
    width: 100%;
    position: absolute;
     top: 0%;
      left: 0;
       height: 100%;
    
       margin-bottom: 10%;
}

.text-wrapperF > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    float: center;
    margin-top: 100px;
}

.text-wrapperF > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapperF > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
}

.text-wrapperF > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}


.learn-moreF {
    position: relative;
    color: black;
    margin-top: 5%;
    text-align: right;
}

.learn-moreF::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px; /* Width of the line */
    height: 1px; /* Height of the line */
    background-color: white;
}

.learn-moreF .fas.fa-arrow-right {
    margin-left: 5px;
}


.text-wrapperF img {
    float: left;/* Adjust the width as needed */
    position: relative;
   width:15%;
   height:auto;
   margin-left:45%;
   margin-top:2%;
   box-shadow:none; 
  
}

.text-wrapperF-2 {
    /* Styles for the text wrapper */
    width: 100%;
}

.text-wrapperF-2 > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    float: center;
    margin-top: 100px;
}

.text-wrapperF-2 > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapperF-2 > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
}

.text-wrapperF-2 > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.content-wrapperF {
  

    border-radius: 70px; /* Add rounded corners */

    margin-right: 500px;
  
   
    /* edit */
width: 90%; 
height: auto;
 background: #E35F5F;
  float: left;
   margin-left: 5%;
    padding: 20px;
     position: absolute;
      bottom: 10%;
       z-index: 1; 
       top: 40%;
       
       
}

.content-wrapperF > h3 {
color: white; 
font-size: 32px;
 margin-top: 1%;
 margin-left:4%;
}

.content-wrapperF > p {
 color: white;
  font-size: 22px;
   line-height: 3rem;
    margin-top: 2%; padding: 2%;
    margin-left:2%;
}

.learn-moreF {
    position: relative;
    color: white;
    margin-top: 0%;
    text-align: right;
    font-size: 20px;
 margin-right: 3%;
  
}

.learn-moreF::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px; /* Width of the line */
    height: 1px; /* Height of the line */
    background-color: white;
}

.learn-moreF > p {
 font-weight: bold;
 font-size:16px; 
}

.learn-moreF .fas.fa-arrow-right {
    margin-left: 5px;
}

.text-wrapperF-2 {
    position: relative;
}
.title-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
    pointer-events: none; 
 
    margin-top: -10%;
   
          
}

.image-container {
    display: inline-block;
    position: relative;
   
}

@media only screen and (max-width: 430px)  { 

    .imageF {
        float: left;
        margin-right: 100px;
    
    }
    
    
    .homeF-container {
        background: #555555;
      
        height: 60vh;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        position: relative; 
        overflow: hidden;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
        padding: 10px;
        min-height: 200px;
      
    }
    
    .homeFs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    
    .homeF__title {
        color: #fff;
        text-align: center;
    }
    
    .homeF > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .homeF__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 80%;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    
    .homeF__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .homeF__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeF__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeF__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeF__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .homeF__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .homeF__item__link {
        width: 100%;
    }
    
    .homeF__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .homeF__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .homeF__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .homeF__item__img:hover {
        transform: scale(1.1);
    }
    
    .homeF__item__info {
        padding: 20px 30px 30px;
    }
    
    .homeF__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    .homeF-container > h1 {
        color: #E34B4B;
        font-size: 48px;
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .homeF-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .homeF-container > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .homeF-container > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .homeF-btns {
        margin-top: 20px; /* Adjust the distance from the top as needed */
        margin-left: 20px; /* Adjust the distance from the left as needed */
    }
    
    .homeF-container img {
        width: 100%; /* Set width to 500px */
        height: auto; /* Set height to 500px */
        border-radius: 0%; /* Apply rounded borders */
        float: left; /* Align the image to the left */
       border-radius: 30px;
        margin-right: 0px; /* Add margin for spacing */
        margin-top: -35%;
        overflow: hidden;
        margin-bottom: 2%;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
        z-index: 0;
        
        
    }
    
    .text-wrapperF {
        /* Styles for the text wrapper */
        width: 100%;
        position: absolute;
         top: 0%;
          left: 0;
           height: 100%;
           margin-top: 0%;
          
    }
    
    .text-wrapperF > h1 {
        color: #E34B4B;
        font-size: 22px;
        text-align: left; 
        float: center;
       margin-top: 0px;
    }
    
    .text-wrapperF > h2 {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
    }
    
    .text-wrapperF > p {
        color: #FFF;
        font-size: 12px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 10%;
    }
    
    .text-wrapperF > h3 {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    
    .learn-moreF {
        position: relative;
        color: black;
        margin-top: 5%;
        text-align: right;
    }
    
    .learn-moreF::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 80px; /* Width of the line */
        height: 1px; /* Height of the line */
        background-color: white;
    }
    
    .learn-moreF .fas.fa-arrow-right {
        margin-left: 5px;
    }
    
    
    .text-wrapperF img {
        float: left;/* Adjust the width as needed */
        position: relative;
       width:15%;
       height:auto;
       margin-left:45%;
       margin-top:2%;
       box-shadow:none; 
    }
    
    .text-wrapperF-2 {
        /* Styles for the text wrapper */
        width: 100%;
    }
    
    .text-wrapperF-2 > h1 {
        color: #E34B4B;
        font-size: 60px;
        text-align: left; 
        float: center;
        margin-top: 100px;
    }
    
    .text-wrapperF-2 > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapperF-2 > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 10%;
    }
    
    .text-wrapperF-2 > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .content-wrapperF {
      
    
        border-radius: 70px; /* Add rounded corners */
    
        margin-right: 500px;
      
       
        /* edit */
    width: 100%; 
    height: auto;
     background: transparent;
      float: left;
    margin: 0 auto;
     margin-top: 22%;
         position: absolute;
          bottom: 10%;
           z-index: 1; 
           top: 35%;
    }
    
    .content-wrapperF > h3 {
    color: #E34B4B; 
    font-size: 16px;
     margin-top: 1%;
     margin-left:4%;
     font-weight: bold;
    }
    
    .content-wrapperF > p {
     color: white;
      font-size: 12px;
       line-height: 2vh;
        margin-top: 2%; padding: 2%;
        margin-left:2%;
    }
    
    .learn-moreF {
        position: relative;
        color: white;
        margin-top: 0%;
        text-align: right;
        font-size: 10px;
     margin-right: 3%;
      
    }
    
    .learn-moreF::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 60px; /* Width of the line */
        height: 1px; /* Height of the line */
        background-color: #E34B4B;
    }
    
    .learn-moreF > p {
     font-weight: bold;
     font-size:10px; 
     color: #E34B4B;
    }
    
    .learn-moreF .fas.fa-arrow-right {
        margin-left: 5px;
    }
    
    .text-wrapperF-2 {
        position: relative;
    }
    .title-background {
        position: absolute;
         top: 0;
          left: 2.5%;
           width: 95%;
            height:25%;
            background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
              z-index: 0;
              margin: 0 auto;
              
    }
  
}

