.imageE {
    float: left;
    margin-right: 100px;
}

.homeE-container {
    background: #555555;
  min-height: 800px;
  max-height: 1000px;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative; 
    overflow: hidden;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
    
    
}


.homeEs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.homeE__title {
    color: #fff;
    text-align: center;
}

.homeE > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}



.homeE__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.homeE__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeE__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeE__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeE__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.homeE__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.homeE__item__link {
    width: 100%;
}

.homeE__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.homeE__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;

    background-color: #1f98f4;
    box-sizing: border-box;
}

.homeE__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.homeE__item__img:hover {
    transform: scale(1.1);
}

.homeE__item__info {
    padding: 20px 30px 30px;
}

.homeE__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}



.homeE-container > h1 {
    color: #E34B4B;
    font-size: 48px;
    margin: 0 auto; 
    margin-right: 35%;
}

.homeE-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.homeE-container > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.homeE-container > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.homeE-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.homeE-container img {
    border-radius: 10%; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 30px;
    margin-right: 0px; /* Add margin for spacing */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
    float: center;
    margin-left: '';
     width: 800px;
    height: auto;
    margin-left: 35%;
}

.text-wrapperE {
    /* Styles for the text wrapper */
    width: 100%;
    height: 100vh;
}

.text-wrapperE > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    float: center;
    margin-top: 100px;
}

.text-wrapperE > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapperE > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
}

.text-wrapperE > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}



.learn-moreE {
    position: relative;
    color: black;
    margin-top: 5%;
    text-align: right;
    font-size: 16px;
}

.learn-moreE::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px; /* Width of the line */
    height: 1px; /* Height of the line */
    background-color: white;
}

.learn-moreE .fas.fa-arrow-right {
    margin-left: 5px;
}

.text-wrapperE {
    position: relative;
    overflow: hidden;
}

.text-wrapperE img {
    float: left;/* Adjust the width as needed */
    position: relative;
}

.text-wrapperE-2 {
    /* Styles for the text wrapper */
    width: 100%;
}

.text-wrapperE-2 > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    float: center;
    margin-top: 100px;
}

.text-wrapperE-2 > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapperE-2 > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
}

.text-wrapperE-2 > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.content-wrapperE {

    padding: 50px; /* Add padding for spacing */
    border-radius: 70px; /* Add rounded corners */
   
    margin-top: 70px;
    z-index: 1;

    
    position: absolute;
    width: 800px;
     height: 500px;
      background: #E35F5F;
      float: left;
      margin-left: 10% ;
}

.content-wrapperE > h3 {
    color: white;
    font-size: 32px;
     margin-bottom: 20px;

}
.content-wrapperE > p {
    color: white;
    font-size: 22px;
     margin-top: 5%;
     line-height: 2.5rem;
     padding: 2%;
}

.learn-moreE {
    position: relative;
    color: white;
    margin-top: 10%;
    text-align: right;
    font-size: 20px;
  
}

.learn-moreE::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px; /* Width of the line */
    height: 1px; /* Height of the line */
    background-color: white;
}

.learn-moreE > p {
font-weight:bold;
font-size: 16px;
margin-top:-5%;
}
.learn-moreE .fas.fa-arrow-right {
    margin-left: 5px;
}

.text-wrapperE-2 {
    position: relative;
}
@media only screen and (max-width: 430px)  { 
    .imageE {
        float: left;
        margin-right: 100px;
    }
    
    .homeE-container {
        background: #555555;
      
        height: auto;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        position: relative; 
        overflow: hidden;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
        padding: 10px;
        min-height: 200px;
        
        
    }
    
    
    .homeEs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    
    .homeE__title {
        color: #fff;
        text-align: center;
    }
    
    .homeE > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    
    
    .homeE__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .homeE__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeE__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeE__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeE__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .homeE__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .homeE__item__link {
        width: 100%;
    }
    
    .homeE__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .homeE__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
    
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .homeE__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .homeE__item__img:hover {
        transform: scale(1.1);
    }
    
    .homeE__item__info {
        padding: 20px 30px 30px;
    }
    
    .homeE__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    
    
    
    .homeE-container > h1 {
        color: #E34B4B;
        font-size: 22px;
        margin: 0 auto; 
       
    }
    
    .homeE-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .homeE-container > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .homeE-container > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .homeE-btns {
        margin-top: 20px; /* Adjust the distance from the top as needed */
        margin-left: 20px; /* Adjust the distance from the left as needed */
    }
    
    .homeE-container img {
    border-radius: 10%; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 30px;
  
    float: center;

 
     width: 100%;
    height: auto;
    margin: 0 auto;
        
   
    }
    
    .text-wrapperE {
        /* Styles for the text wrapper */
        width: 100%;
       height: auto;
    }
    
    .text-wrapperE > h1 {
        color: #E34B4B;
        font-size: 16px;
        text-align: left; 
        float: center;
        margin-top: 100px;
    }
    
    .text-wrapperE > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapperE > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 10%;
    }
    
    .text-wrapperE > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .content-wrapperE {
        /* Add padding for spacing */
        border-radius: 70px; /* Add rounded corners */
  
     
        z-index: 1;
    
        
        position: relative;
        width: 100%;
         height: auto;
          background: #555555;
          float: left;
          margin-left: 2% ;
          color: white;
           font-size: 32px;
            margin-bottom: 20px;
            margin-top: -10%;
    
    }


.content-wrapperE > h3 {
    color: #E34B4B;
    font-size: 16px;
    margin-top: 10px;
     margin-bottom: 10px;

}
.content-wrapperE > p {
    color: white;
    font-size: 12px;
     margin-top: 5%;
     line-height: 2vh;
     padding: 2%;
}
    
    .learn-moreE {
        position: relative;
        color: black;
        margin-top: 5%;
        text-align: right;
        font-size: 10px;
    }
    
    .learn-moreE::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 60px; /* Width of the line */
        height: 1px; /* Height of the line */
        background-color: white;
    }
    .learn-moreE > p {
        font-weight:bold;
        font-size: 10px;
        margin-top:-5%;
        }
    
    .learn-moreE .fas.fa-arrow-right {
        margin-left: 5px;
    }
    
    .text-wrapperE {
        position: relative;
        overflow: hidden;
    }
    
    .text-wrapperE img {
        float: left;/* Adjust the width as needed */
        position: relative;
    }
    
    .text-wrapperE-2 {
        /* Styles for the text wrapper */
        width: 100%;
    }
    
    .text-wrapperE-2 > h1 {
        color: #E34B4B;
        font-size: 60px;
        text-align: left; 
        float: center;
        margin-top: 100px;
    }
    
    .text-wrapperE-2 > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapperE-2 > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 10%;
    }
    
    .text-wrapperE-2 > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
 
    .learn-moreE {
        position: relative;
        color: #E34B4B;
        margin-top: 10%;
        text-align: right;
        font-size: 20px;
      
    }
    
    .learn-moreE::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 60px; /* Width of the line */
        height: 1px; /* Height of the line */
        background-color: #E34B4B;
    }
    
    .learn-moreE .fas.fa-arrow-right {
        margin-left: 5px;
    }
    
    .text-wrapperE-2 {
        position: relative;
    }


}