.govt.image {
    object-fit: cover;
    width: 100%;
    position: fixed;
    z-index: -1;
}
.govt.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}
.govt.hero-container2 {
    background: url('../Govt-hero.jpeg') center center/cover no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5%;
    border-radius: 0 0  80px 80px; 
    max-width:100%;
    z-index: 1; 
} 

.govt.hero-container {
    max-height: 1000px;
    height: 60vh;
    width: 100%;
    max-width:1632px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 100px;
    overflow: hidden; 
    position: relative; 
    background-color: #555555;
    z-index: 1;
}
.govt.hero-container2 > img {
   display: none;
}


.govt.hero-container2 ::after {
    content: ''; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
   
}
.govt.hero-container2 > h1 {
    position: relative;
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    padding-bottom: 2%;
    margin-top: 10%;
    margin-left: -5%;
}

.govt.hero-container2 > h2 {
    display: flex;
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.govt.hero-container2 > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 50%;
}

.govt.hero-btns {
    margin-top: 20%; /* Adjust the distance from the top as needed */
    margin-left: 0%; /* Adjust the distance from the left as needed */
    z-index: 1;

}
.white-text {
    color: #FFF; /* White color */
}
.govt.hero-btnss {
   margin-top: 30%;
    margin-left: 0%; /* Adjust the distance from the left as needed */
    z-index: 1;
}

@media screen and (max-width: 430px) {  
    .govt.image {
        object-fit: cover;
        width: 100%;
        position: fixed;
        z-index: -1;
    }
    .govt.homecs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    .govt.hero-container2 {
        background: url('../Govt-hero.jpeg') center center/cover no-repeat;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: flex;
        flex-direction:column;
        padding-left: 0%;
        padding-right: 0%;
        padding-top: 50%;
        padding-bottom: 0%;
        border-radius: 0 0  0px 0px; 
        max-width:100%;
        z-index: 1; 
    } 
    
    .govt.hero-container2 > img {
        width: 100%;
        height: auto;
        z-index: -1;
        display: contents;
    }
    
    .govt.hero-container {

        height: 50vh;
        width: 100%;
        max-width:100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        overflow: hidden; 
        position: relative; 
        background-color: #555555;
        z-index: 1;
        
    }
    
    
    
    .govt.hero-container2 ::after {
        content: ''; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
      
       
    }
    .govt.hero-container2 > h1 {
        position: relative;
        color: #E34B4B;
        font-size: 40px;
        text-align: left; 
        padding-bottom: 2%;
        margin-top: 40%;
        margin-left: -5%;
    }
    
    .govt.hero-container2 > h2 {
        display: flex;
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .govt.hero-container2 > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 50%;
    }
    
    .govt.hero-btns {
        margin-top: 20%; /* Adjust the distance from the top as needed */
        margin-left: 0%; /* Adjust the distance from the left as needed */
        z-index: 1;
    display: none;
    }
    .white-text {
        color: #FFF; /* White color */
    }
    .govt.hero-btnss {
       margin-top: 30%;
        margin-left: 0%; /* Adjust the distance from the left as needed */
        z-index: 1;
        display: none;
    }


}