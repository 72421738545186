:root {
  
}

.btn.add-button {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary.add-button {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline.add-button {
    background-color: #E34B4B;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline2.add-button {
    background-color: #E34B4B;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-radius: 50%;
    float: right;
    margin-right: 50px;
    width: 70px;
    height: 60px;
}

.btn--outline3.add-button {
    background-color: #E34B4B;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium.add-button {
    padding: 8px 20px;
    font-size: 16px;
}

.btn--large.add-button {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large2.add-button {
    padding: 12px 26px;
    font-size: 30px;
}

.btn--medium.add-button:hover, .btn--large.add-button:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.btn--custom.add-button {
    background-color: #E34B4B;
    color: #FFF;
}

.popup.add-button {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   display: flex;
    margin-top: -5%;
    background-color: #333333;
    box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.2);
   
    flex-direction: column;
    width: 60vh;
    height: 55vh;
    max-width: 550px;
    max-height: 500px;
    overflow: visible;
    z-index: 1;
    border-radius: 10%;
    
   
    
}

.popup-content.add-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    
}

.popup-content.add-button form {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.popup-content.add-button input {
    margin-bottom: 10px;
    background-color: #4e4e4e;
    border: none;
    padding: 10px;
   
}

.inline-inputs {
    display: flex;
    gap: 5px;
    color: white;
    margin-left: -15%;
    
}

.inline-inputs .footer-input {
    width: 12rem;
    height: 3rem;;
  
   
color: lightgray;
}

.inline-inputsb {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 95%;

    margin-left: -18%;
}

.inline-inputsb .footer-input {
 
color: lightgray;
height: 3rem;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 5%;
}

.checkbox-container label {
    margin-left: 10px;
    margin-top: -4%;
    color: #fff;
}

.popup-content.add-button button {
    margin-top: 10px;
}

.popup-close {
    position: absolute;
    top: 7%;
    right: 8%;
    cursor: pointer;
    color: #E34B4B;
    font-size: 22px;
}

.popup-content.add-button > h1 {
    color: #E34b4b;
    text-align: center;
    margin-bottom: 10%;
    margin-top: 7%;
    font-size: 22px;

}
@media screen and (max-width: 430px) { 
    :root {
  
    }
    
    .btn.add-button {
        padding: 8px 20px;
        border-radius: 2px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    
    .btn--primary.add-button {
        background-color: var(--primary);
        color: #242424;
        border: 1px solid var(--primary);
    }
    
    .btn--outline.add-button {
        background-color: #E34B4B;
        color: #fff;
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transition: all 0.3s ease-out;
    }
    
    .btn--outline2.add-button {
        background-color: #E34B4B;
        color: #fff;
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transition: all 0.3s ease-out;
        border-radius: 50%;
        float: right;
        margin-right: 50px;
        width: 70px;
        height: 60px;
    }
    
    .btn--outline3.add-button {
        background-color: #E34B4B;
        color: #fff;
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transition: all 0.3s ease-out;
    }
    
    .btn--medium.add-button {
        padding: 8px 20px;
        font-size: 16px;
    }
    
    .btn--large.add-button {
        padding: 12px 26px;
        font-size: 20px;
    }
    
    .btn--large2.add-button {
        padding: 12px 26px;
        font-size: 30px;
    }
    
    .btn--medium.add-button:hover, .btn--large.add-button:hover {
        background: #fff;
        color: #242424;
        transition: all 0.3s ease-out;
    }
    
    .btn--custom.add-button {
        background-color: #E34B4B;
        color: #FFF;
    }
    
    .popup.add-button {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
       display: flex;
        margin-top: -5%;
        background-color: #333333;
        box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.2);
        flex-direction: column;
        width: 100%;
        height: auto;
        overflow: visible;
        z-index: 1;
        border-radius: 10%;
        
       
        
    }
    
    .popup-content.add-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        
        
    }
    
    .popup-content.add-button form {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    
    .popup-content.add-button input {
        margin-bottom: 10px;
        background-color: #4e4e4e;
        border: none;
        padding: 10px;
       
    }
    
    .inline-inputs {
        display: flex;
        gap: 5px;
        color: white;
        margin-left: -15%;
        
    }
    
    .inline-inputs .footer-input {
        width: 12rem;
        height: 3rem;;
      
       
    color: lightgray;
    }
    
    .inline-inputsb {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 95%;
    
        margin-left: -18%;
    }
    
    .inline-inputsb .footer-input {
     
    color: lightgray;
    height: 3rem;
    }
    
    .checkbox-container {
        display: flex;
        align-items: center;
        margin-top: 5%;
    }
    
    .checkbox-container label {
        margin-left: 10px;
        margin-top: -4%;
        color: #fff;
    }
    
    .popup-content.add-button button {
        margin-top: 10px;
    }
    
    .popup-close {
        position: absolute;
        top: 7%;
        right: 8%;
        cursor: pointer;
        color: #E34B4B;
        font-size: 22px;
    }
    
    .popup-content.add-button > h1 {
        color: #E34b4b;
        text-align: center;
        margin-bottom: 10%;
        font-size: 22px;
        margin-top: 5%;
    
    }

    .submit-btn {
        margin-bottom: 2%;

    }


}