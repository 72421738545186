

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.homecs {
    padding: 4rem;
    background: black; /* Set background to black */
    position: relative;
}
.homec-parent{

}
.homec-container {
    position: relative;
    min-height: 800px;
    height: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;
    background-color: #555555;
    z-index: 0;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the container */
   
}

.homec-container::before,
.homec-container::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    height: 10%;
}

.homec-container::before {
    top: 3%;
    border-radius: 50% 100% 0 0;
    background-color: #555555;
}

.homec-container::after {
    bottom: 0;
    border-radius: 0 0 100% 0;
    background-color: #555555;
}

.homec-container > p {
    font-size: 26px;
}

.homec__title {
    color: #fff;
    text-align: center;
}

.homec > h2 {
    color: #FFF;
    text-align: center;
    margin-bottom: 30px;
}

.homec__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 80%;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.homec__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem;
}

.homec__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homec__arrow-left {
    left: 100px;
}

.homec__arrow-right {
    right: 100px;
}

.homec__items-container {
    overflow-x: auto;
    max-width: 100%;
}

.homec__item {
    flex: 0 0 auto;
    margin: 0 0.5rem;
    width: 600px;
    height: 400px;
    border-radius: 25px;
}

.homec__item__link {
    width: 100%;
}

.homec__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.homec__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    box-sizing: border-box;
}

.homec__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.homec__item__img:hover {
    transform: scale(1.1);
}

.homec__item__info {
    padding: 20px 30px 30px;
}

.homec__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.homec-container > h1 {
    color: #e34b4b;
    font-size: 48px;
    text-align: center;
    padding-left: 7.5%;
    padding-right: 7.5%;
}

.homec-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left;
}

.homec-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left;
    margin: 0 auto;
    margin-right: 30%;
}

.homec-btns {
    margin-top: 20px;
    margin-left: 20px;
}

.homec-container img {
    width: 650px;
    height: 500px;
    border-radius: 10%;
    float: left;
    margin-top: 5%;
    margin-right: 100px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    margin-left: 5%;
}

.text-wrapperC {
    width: 100%;
    overflow: hidden;
}

.text-wrapperC > h1 {
    color: #FFF;
    font-size: 3rem;
    text-align: left;
    margin-top: 100px;
}

.text-wrapperC > h2 {
    color: #FFF;
    font-size: 3rem;
    text-align: left;
}

.text-wrapperC > p {
    color: #FFF;
    font-size: 1.75rem;
    text-align: left;
    margin: 0 auto;
    margin-right: 10%;
    padding-top: 4%;
}

.text-wrapperC > img {
    margin-right: 100px;
}

.img-background {
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: 0;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    width: 650px;
    height: 500px;
    border-radius: 10%;
    float: left;
    margin-right: 0px;
}

.custom-ul {
    color: white;
    font-size: 22px;
    margin-top: 3%;
    padding-left: 60%;
}

.custom-ul li {
    margin-bottom: 3%;
}

@media (max-width: 960px) {
    .homecs {
        padding: 2rem;
    }
    .homec-parent{

    }

    .homec-container {
        padding: 0px;
        min-height: auto;
        height: 60vh; /* Adjust height for smaller screens */
        max-width: 100%; /* Ensure it takes the full width on smaller screens */
        object-fit: cover;
        width:100%;
        z-index: 1;
        
    }
    .img-background {
        background-color: transparent;
        position: absolute;
        z-index: 0;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        width: 0px;
        height: 0px;
        border-radius: 10%;
        float: left;
        margin-right: 0px;
    }

    .homec-container > h1 {
        font-size: 36px;
    }

    .homec-container > h2 {
        font-size: 40px;
    }

    .homec-container > p {
        font-size: 18px;
        margin-right: 0;
    }

    .homec-container > img {
        width: 100%;
        height: auto;
        margin-top: 2%;
        margin-right: 0;
        margin-left: 0;
    }

    .text-wrapperC > img{
        width: 10px;
        height: auto;
        margin-top: 2%;
        margin-right: 0;
        margin-left: 0;
    }

    .text-wrapperC > h1,
    .text-wrapperC > h2 {
        font-size: 2.5rem;
    }

    .text-wrapperC > p {
        font-size: 1.5rem;
        margin-right: 5%;
        padding-top: 2%;
    }

    .homec__arrow-left,
    .homec__arrow-right {
        left: 20px;
        right: 20px;
    }
}

@media (max-width: 430px) {
    .homecs {
        padding: 1rem;
    }

    .homec-container {
        padding: 10px;
        min-height: auto;
        height: 100%; /* Adjust height for even smaller screens */
        max-width: 95%; /* Ensure it takes the full width on smaller screens */
        width:100%
    }

    .homec-container > h1 {
        font-size: 16px;
        margin-bottom: 2%;
    }

    .homec-container > h2 {
        font-size: 16px;
    }

    .homec-container > p {
        font-size: 12px;
        margin-right: 0;
    }

    .homec-container img {
        width: 100%;
        height: auto;
        margin-top: 3%;
        margin-right: 0;
        margin-left: 0;
  
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .text-wrapperC > h1,
    .text-wrapperC > h2 {
        font-size: 1rem;
    }

    .text-wrapperC > p {
        font-size: 0.85rem;
        margin-right: 2%;
        padding-top: 5%;
   
    }

    .homec__arrow-left,
    .homec__arrow-right {
        left: 10px;
        right: 10px;
    }

    .custom-ul {
        font-size: 12px;
        padding-left: 30%;
    }

    .custom-ul li {
        margin-bottom: 2%;
    }
}


