.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.RelayD-container {
    background: #555555;
    max-height: 1000px;
    height: 150vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 4%; /* Adjusted padding-left */
    padding-right: 50px;

    object-fit: contain;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.homecs {
    padding: 4rem;
    position: relative;
}

.RelayD__title {
    color: #fff;
    text-align: center;
}

.RelayD > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.RelayD__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.RelayD__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.RelayD__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayD__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayD__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayD__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.RelayD__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.RelayD__item__link {
    width: 100%;
}

.RelayD__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.RelayD__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.RelayD__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.RelayD__item__img:hover {
    transform: scale(1.1);
}

.RelayD__item__info {
    padding: 20px 30px 30px;
}

.RelayD__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}


.RelayD-container > h1 {
    color:white;
    font-size: 40px;
    text-align: left; 
}

.RelayD-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.RelayD-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.RelayD-btns {
    margin-top: 0%; 
    margin-left: 20px;
}

.RelayD-container img {
    float: right;
     margin-right: 0px;
      height: 700px;
       width: auto;
       border-radius: 40px;
       margin-top: 5%;
       z-index: 1;
}

.text-wrapper-relayd {
    /* Styles for the text wrapper */
    width: 100%;
    margin-top: 3%;
}

.text-wrapper-relayd > h1 {
    color: #E34B4B;
    font-size: 32px;
    text-align: left; 
    float: center;
    margin-top: 3%;

}

.text-wrapper-relayd > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-relayd > p {
    color: #FFF;
    line-height: 2.5rem;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 50%;
    margin-top: 1%;
  
}

.text-wrapper-relayd > video {
    float: right;
     margin-right: 0px;
      height: 60%;
       width: auto;
       border-radius: 20px;
       z-index: 1;
}

.hero-btns-relayd {
    margin-top: 20px;
    margin-left: 20px;
}
@media screen and (max-width: 430px){ 
    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    
    .RelayD-container {
        background: #555555;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 4%; /* Adjusted padding-left */
        padding-right: 50px;
        max-height: none;
        object-fit: contain;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
    }
    
    .homecs {
        padding: 4rem;
        position: relative;
    }
    
    .RelayD__title {
        color: #fff;
        text-align: center;
    }
    
    .RelayD > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .RelayD__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 1240px;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    
    .RelayD__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .RelayD__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .RelayD__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .RelayD__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .RelayD__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .RelayD__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .RelayD__item__link {
        width: 100%;
    }
    
    .RelayD__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .RelayD__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .RelayD__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .RelayD__item__img:hover {
        transform: scale(1.1);
    }
    
    .RelayD__item__info {
        padding: 20px 30px 30px;
    }
    
    .RelayD__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    
    
    .RelayD-container > h1 {
        color:white;
        font-size: 40px;
        text-align: left; 
    }
    
    .RelayD-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .RelayD-container > p {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .RelayD-btns {
        margin-top: 0%; 
        margin-left: 20px;
    }
    
    .RelayD-container img {
        float: right;
        margin-left: 20px;
       
        padding: 0px;
        margin: 0 auto;
          height: auto;
           width: 100%;
           border-radius: 50px ;
           z-index: 1;
           margin-bottom: 5%;
    }
    
    .text-wrapper-relayd {
        /* Styles for the text wrapper */
        width: 100%;
        margin-top: 3%;
        padding: 20px;
    }
    
    .text-wrapper-relayd > h1 {
        color: #E34B4B;
        font-size: 1.5rem;
        text-align: left; 
        float: center;
        margin-top: 5%;
    
    }
    
    .text-wrapper-relayd > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapper-relayd > p {
        color: #FFF;
        line-height: 1.5rem;
        text-align: left; 
        float: center;
        margin: 0 auto; 
     font-size: 1rem;
        margin-top: 1%;
        
      
    }

    .text-wrapper-relayd > video {
        float: right;
        margin-left: 20px;
       
        padding: 0px;
        margin: 0 auto;
          height: auto;
           width: 100%;
           border-radius: 0px 100px 0 0px;
           z-index: 1;
           margin-bottom: 5%;
    }
    
    .hero-btns-relayd {
        margin-top: 20px;
        margin-left: 20px;
    }


}