.teams-cards2 {
    background: #555555;
    width:100%;
    position: relative;
   
    flex-direction: column;
  align-items: center; /* Center horizontally */
    padding-top: 1%;
    overflow: hidden;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.teams-cards2__title {
    color: #fff;
    text-align: center;
}

.teams-cards2 > h2 {
    color: #E34b4b;
    margin-top: 2%;
    font-size: 32px;
    text-align: center; 
    margin-bottom: 30px;
}

.teams-cards2__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background: #555555;
  
    float: center;
    justify-content: center; /* Center horizontally */

}

.teams-cards2__items {
    display: flex;
    overflow-x: auto;
   
    justify-content: center; /* Center horizontally */
}

.teams-cards2__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: black;
    cursor: pointer;
}

.teams-cards2__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.teams-cards2__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.teams-cards2__items-container {
    overflow-x: scroll; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
    max-height: 100%;
    overflow: hidden;
}

.teams-cards2__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 1.5rem; /* Adjust margin between cards */
    width: 350px; /* Set the width of each card */
    height: 450px; /* Set the height of each card */
    border-radius: 10px; /* Add rounded corners */
    background-color: #333333;
    overflow: hidden;
    
}

.teams-cards2__item__pic-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5%;
    overflow: hidden;
    border-radius: 10px; /* Add rounded corners */
    background-color: rgb(4, 4, 4); /* Ensure image background color is white */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.teams-cards2__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: black;
    box-sizing: border-box;
}

.teams-cards2__item__text {
    color: #E34b4b; /* Change text color to white */
    font-size: 14px; /* Decrease font size as needed */
    line-height: 1.5; /* Adjust line height for better readability */
    text-align: center; /* Center-align the text */
    font-weight: bold;

   
}

.teams-cards3__item__text {
    color: #fff; /* Change text color to white */
    font-size: 11px; /* Decrease font size as needed */
    line-height: 1.5; /* Adjust line height for better readability */
    text-align: left; /* Center-align the text */
    float: center;
    padding-right: 1%;
    position: absolute;
    bottom: 15%;
}

.teams-cards4__item__text {
    color: #fff; /* Change text color to white */
    font-size: 12px; /* Decrease font size as needed */
    line-height: 1.5; /* Adjust line height for better readability */
    text-align: right; /* Center-align the text */
    float: right;
    padding-right: 1%;
    position: absolute;
    left: 70%;
    bottom: 12%;
}

.teams-cards2__item__img {
    width: 112%;
    height: 65%; /* Adjusted height for the image */
    margin-bottom: 5%; /* Space between image and text */
    margin-top: -20%;
    margin-left: -6%;
    object-fit: fill;
    overflow: hidden;
}

@media screen and (max-width: 430px) { 

    .teams-cards2 {
        background: #555555;
        width:100%;
        position: relative;
       
        flex-direction: column;
      align-items: center; /* Center horizontally */
        padding-top: 1%;
        overflow: hidden;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
    }
    
    .teams-cards2__title {
        color: #fff;
        text-align: center;
    }
    
    .teams-cards2 > h2 {
        color: #E34b4b;
        margin-top: 2%;
        font-size: 1.2rem;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .teams-cards2__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        overflow: visible;
        background: #555555;
      
        float: center;
        justify-content: center; /* Center horizontally */
    
    }
    
    .teams-cards2__items {
        display: flex;
        overflow-x: auto;
        
        justify-content:flex-start; /* Center horizontally */
    }
    
    .teams-cards2__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: black;
        cursor: pointer;
    }
    
    .teams-cards2__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .teams-cards2__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .teams-cards2__items-container {
        overflow-x: scroll; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
        max-height: 100%;
        overflow: visible;
    }
    
    .teams-cards2__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 1.5rem; /* Adjust margin between cards */
        width: 300px; /* Set the width of each card */
        height: 350px; /* Set the height of each card */
        border-radius: 10px; /* Add rounded corners */
        background-color: #555555;
        overflow: visible;
        
    }
    
    .teams-cards2__item__pic-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 5%;
        overflow: hidden;
        border-radius: 10px; /* Add rounded corners */
        background-color: rgb(4, 4, 4); /* Ensure image background color is white */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .teams-cards2__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: black;
        box-sizing: border-box;
    }
    
    .teams-cards2__item__text {
        color: #E34b4b; /* Change text color to white */
        font-size: 14px; /* Decrease font size as needed */
        line-height: 1.5; /* Adjust line height for better readability */
        text-align: center; /* Center-align the text */
        font-weight: bold;
    
       
    }
    
    .teams-cards3__item__text {
        color: #fff; /* Change text color to white */
        font-size: 11px; /* Decrease font size as needed */
        line-height: 1.5; /* Adjust line height for better readability */
        text-align: left; /* Center-align the text */
        float: center;
        padding-right: 1%;
        position: absolute;
        bottom: 15%;
    }
    
    .teams-cards4__item__text {
        color: #fff; /* Change text color to white */
        font-size: 12px; /* Decrease font size as needed */
        line-height: 1.5; /* Adjust line height for better readability */
        text-align: right; /* Center-align the text */
        float: right;
        padding-right: 1%;
        position: absolute;
        left: 70%;
        bottom: 12%;
    }
    
    .teams-cards2__item__img {
        width: 112%;
        height: 55%; /* Adjusted height for the image */
        margin-bottom: 5%; /* Space between image and text */
        margin-top: -20%;
        margin-left: -6%;
        object-fit: fill;
        overflow: hidden;
    }

}