.cards2 {
    background: #555555;
    position: relative;
 
    padding-top: 1%;
    overflow: hidden; /* Hide scrollbars */
    max-width: 85%; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.cards2__title {
    color: #fff;
    text-align: center;
}

.cards2 > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.cards2__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 85%;
    width: 80%;
    margin: 0 auto;
    background: #555555;
   
    overflow-x: scroll;
    scroll-behavior: smooth;
    overflow: hidden; /* Hide scrollbars */
}

.cards2__items {
    display: flex;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
  
}

.cards2__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 1.5rem; /* Adjust margin between cards */
    width: 300px; /* Set the width of each card */
    height: 350px; /* Set the height of each card */
    border-radius: 10px; /* Add rounded corners */
    background-color: #333333;
}

.cards2__item__pic-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2%;
    overflow: hidden;
    border-radius: 10px; /* Add rounded corners */
    background-color: rgb(4, 4, 4); /* Ensure image background color is white */
}

.cards2__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: black;
    box-sizing: border-box;
}

.cards2__item__img {
    width: 111%;
    height: 65%; /* Adjusted height for the image */
    margin-bottom: 5%; /* Space between image and text */
    margin-top: -20%;
    margin-left: -5%;
    object-fit: fill;
    overflow: hidden;
}

.cards2__item__text {
    color: #fff; /* Change text color to white */
    font-size: 12px; /* Decrease font size as needed */
    line-height: 1.5; /* Adjust line height for better readability */
    text-align: center; /* Center-align the text */
}

.cards3__item__text {
    color: #fff; /* Change text color to white */
    font-size: 12px; /* Decrease font size as needed */
    line-height: 1.5; /* Adjust line height for better readability */
    text-align: left; /* Center-align the text */
    float: center;
    padding-right: 1%;
    position: absolute;
    bottom: 20%;
}

.cards4__item__text {
    color: #fff; /* Change text color to white */
    font-size: 12px; /* Decrease font size as needed */
    line-height: 1.5; /* Adjust line height for better readability */
    text-align: right; /* Center-align the text */
    float: right;
    padding-right: 1%;
    position: absolute;
    left: 70%;
    bottom: 10%;
}

@media only screen and (max-width: 1024px) {
    .cards2__item {
        margin-bottom: 2rem;
    }
}

.card-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color */
}

.card-link:hover {
    text-decoration: none; /* Remove underline on hover */
    color: inherit; /* Inherit text color on hover */
}
