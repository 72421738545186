.team.image {
    object-fit: cover;
    width: 100%;

    position: fixed;
    z-index: -1;
}
.team.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}
.team.hero-container {
    background: url('../About Us.jpeg') center center/cover no-repeat;
    max-height: 1000px;
    height: 60vh;
    width: 100%;
    max-width:1632px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    object-fit: contain;
    z-index: 0;
    border-radius: 0 0  80px 80px; 
    overflow: hidden;
  
} 

.team.hero-container > h1 {
    position: relative;
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    padding-bottom: 2%;
    margin-top: 0%;
}

.team.hero-container > h2 {
    display: flex;
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.team.hero-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 20%;
    margin-top: 5%;
}

.team.hero-btns {
    margin-top: 5%; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
   width: 120%;
   z-index: 1;
}
.white-text {
    color: #FFF; /* White color */
}

@media screen and (max-width: 430px) { 
    .team.image {
        object-fit: cover;
        width: 100%;
    
        position: fixed;
        z-index: -1;
    }
    .team.homecs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    .team.hero-container {
        background: url('../About Us.jpeg') center center/cover no-repeat;
        height: 50vh;
        width: 100%;
        max-width:100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        object-fit: contain;
        z-index: 0;
        border-radius: 0 0  0px 0px; 
        overflow: hidden;
      
    } 
    
    .team.hero-container > h1 {
        position: relative;
        color: #E34B4B;
        font-size: 30px;
        text-align: left; 
      
        margin-top: 0%;
        margin: 0 auto;
        padding: 2%;
    }
    
    .team.hero-container > h2 {
        display: flex;
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .team.hero-container > p {
        color: #FFF;
        font-size: 20px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 20%;
        margin-top: 5%;
        padding-left: 20px;
    }
    
    .team.hero-btns {
        margin-top: 5%; /* Adjust the distance from the top as needed */
        margin-left: 20px; /* Adjust the distance from the left as needed */
       width: 120%;
       z-index: 1;
       display: none;
    }
    .white-text {
        color: #FFF; /* White color */
    }

}