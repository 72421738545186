.grantcards2 {
    background: #555555;
   
    position: relative;
    padding-top: 1%;
}

.grantcards2__title {
    color: #fff;
    text-align: center;
}

.grantcards2 > h1 {
    color: #E34B4B;
    text-align: center; 
    margin-bottom: 30px;
    font-size: 42px;
}

.grantcards2 > h2 {
    color: #fff;
    text-align: center; 
    margin-bottom: 20px;
}

.grantcards2__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 85%;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    background: #555555;
   
    scroll-behavior: smooth;
}

.grantcards2__items {
    display: flex;
    padding: 1rem;
    animation: scroll 30s linear infinite;
}

.grantcards2__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: black;
    cursor: pointer;
}

.grantcards2__arrow-left {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.grantcards2__arrow-right {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.grantcards2__items-container {
    max-width: 100%;
    max-height: 100%;
}

.grantcards2__item {
    flex: 0 0 auto;
    margin: 0 1.5rem;
    width: 350px;
    height: 400px;
    border-radius: 10px;

    margin-bottom: 1%;
}

.grantcards2__item__link {
    text-decoration: none;
    color: inherit;
}

.grantcards2__item__pic-wrap {
    position: relative;
    margin-top: -8%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    background-color: #E34B4B;

}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.grantcards2__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    max-width: calc(100% - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #E34B4B;
    box-sizing: border-box;
}

.grantcards2__item__img {
    display: block;
    background: url('../hero-section.jpeg') center center/cover no-repeat;
    width: 114%;
    height: 50%;
    object-fit: cover;
    margin-bottom: 3%;
    margin-top: 0%;
    margin-left: 0%;
    object-fit: cover;
}

.grantcards2__item__info {
    padding: 20px 30px 30px;
}

.grantcards2__item__text {
    color: #fff;
    font-size: 14px;
    float: center;
    margin-left: 3%;
    margin-right: 3%;
    left:3%;
    position: absolute;
    font-weight: bold; 
}

.grantcards3__item__text {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    float: center;
    left: 3%; 
    padding-right: 1%;
    position: absolute;
  top: 60%;
    margin-top: 5%;
}

.grantcards4__item__text {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    float: center;
    padding-right: 1%;
    position: absolute;
    left: 10%; 
    top: 82%;
    margin-top: 5%;
}

.grantcards5__item__text {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    float: center;
    padding-right: 1%;
    position: absolute;
    left: 10%; 
    bottom: 45px;
}

.grantcards6__item__text {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    float: right;
    padding-right: 1%;
    position: absolute;
    left: 65%; 
    top: 91%;
}
/* For WebKit browsers (Chrome, Safari) and Edge */
.grantcards2__container::-webkit-scrollbar {
    display: none;
}

/* For Firefox */
.grantcards2__container {
    scrollbar-width: none; /* Firefox */
}

/* For Internet Explorer and Microsoft Edge */
.grantcards2__container {
    -ms-overflow-style: none; /* IE and Edge */
}

@media only screen and (max-width: 430px) {
    .grantcards2 > h1 {
        color: #E34B4B;
        text-align: center; 
        margin-bottom: 30px;
        font-size: 32px;
    }
}
