.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.hero-container {
    min-height: 800px;
    max-height: 1000px;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10%; /* Use relative padding */
    overflow: hidden;
    position: relative;
    background-color: #555555;
    z-index: 1;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: flex-start; /* Align items to the start */
    padding: 5%;
    background: url('../hero-section.jpeg') center center/cover no-repeat;
    border-radius: 0 0 200px 0px;
    z-index: 1;
    overflow: hidden; /* Ensure no scrolling inside the background */
}

.hero-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-background > h1 {
    color: #E34B4B;
    font-size: 2.5rem; /* Reduce font size */
    text-align: left;
    font-family: 'Poppins', sans-serif;
}

.hero-background > h2 {
    color: #FFF;
    font-size: 2.5rem; /* Reduce font size */
    text-align: left;
    font-family: 'Poppins', sans-serif;
}

.hero-background > p {
    color: #FFF;
    font-size: 1.2rem;
    text-align: left;
    margin: 0 auto;
    margin-right: 50%;
    margin-top: 2%;
}

.hero-btns-sol {
    margin-top: 20px;
    margin-left: 0;
}


@media (max-width: 430px) {
    .homecs {
        padding: 1rem;
    }

    .hero-container {
        padding: 0 2%;
        height: 50vh; /* Adjust height for even smaller screens */
        max-width: 100%; /* Ensure it takes the full width on smaller screens */
       
    }

    .hero-background {
        padding: 1.5%;
        border-radius: 0;
        padding: 5%;
        
    }

    .hero-background > h1 {
        font-size: 1.5rem;
    }

    .hero-background > h2 {
        font-size: 1.2rem;
    }

    .hero-background > p {
        font-size: 0.75rem;
        margin-right: 0;
        margin-top: 10%;
    }

    .hero-btns-sol {
        margin-top: 5px;
    }
}
