.navbar {
    background-color: #555555;
   
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    
    
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    min-width: 100%;

    
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw; /*was 60vw */
   
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    cursor: pointer;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.search-container {
    display: flex;
    align-items: center;
}

.search-container input[type="text"] {
    padding: 0.5rem;
    margin-right: 0.5rem;
    border: none;
    border-radius: 4px;
}

.search-btn {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.2rem;
}

.search-btn:hover {
    opacity: 0.8;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #333333;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    min-width: 160px;
    z-index: 1;
}

.dropdown-menu.active {
    display: block;
}

.dropdown-item {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-item:hover {
    background-color: #555;
}
.nav-btns{
    width:10px;
}


@media screen and (max-width: 960px) {
    .navbar-container {
        padding: 0 10px;
    }
    .nav-menu {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        background-color: #333333;
    }
    .nav-menu.active {
        left: 0;
    }
    .nav-item {
        height: 60px;
    }
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .menu-icon {
        display: block;
    }
}

@media screen and (max-width: 430px) {
    
    .navbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        min-width: 100%;
        padding: 0 5px;
    }

    .navbar-logo img {
        height: 30px;
        margin-right: 5px;
    }

    .navbar-logo p {
        font-size: 1rem;
        margin-left: 2px;
        padding-left: 1px;
    }

    .nav-menu {
        display: none; /* Hide the main menu initially */
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #333333;
        z-index: 1;
    }

    .nav-menu.active {
        display: flex; /* Show menu when active */
        flex-direction: column; /* Display items in a column */
        align-items: center; /* Center items vertically */
    }

    .nav-item {
        width: 100%; /* Full width for each item */
        text-align: center; /* Center align text */
        height: auto; /* Auto height */
    }

    .nav-links {
        padding: 1rem 0; /* Adjust padding for better spacing */
    }

    .nav-item:nth-child(2) .nav-links {
        cursor: pointer;
       
    }
    
    .dropdown-menu {
        display: none; /* Always show dropdown */
        position: static; /* Remove absolute positioning */
        background-color: #333333; /* Background color for dropdown */
        box-shadow: none; /* Remove box shadow */
        width: 100%; /* Full width of parent */
    }
    .dropdown-menu.active {
        display: block;
        position: static; /* Remove absolute positioning */
        background-color: #333333; /* Background color for dropdown */
        box-shadow: none; /* Remove box shadow */
        width: 100%; /* Full width of parent */
    }

    .dropdown-item {
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        width: 100%; /* Full width for dropdown items */
        text-align: center; /* Center align text */
    }

    .dropdown-item:hover {
        background-color: #555;
    }

    .menu-icon {
        display: block; /* Show hamburger icon */
        cursor: pointer;
    }
    
}
