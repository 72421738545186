.cards {
    background: #555555;
 
    position: relative;
    width: 100%;
    max-height: 500px;
    height: 50vh;
    max-width: 85%; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.cards__title {
    color: #fff;
    text-align: center;
}

.cards > h2 {
    color: #FFF;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 4%;
    font-size: 32px;
}

.cards__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 100%;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    max-width: 1200px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.cards__container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.cards__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    width: 100%;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
}

.cards__items::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.cards__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.cards__arrow-left {
    position: absolute;
    top: 55%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.cards__arrow-right {
    position: absolute;
    top: 55%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.cards__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
    max-height: 80%;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
}

.cards__items-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.cards__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 1rem; /* Adjust margin between cards */
    width: 150px; /* Set the width of each card */
    height: 200px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.cards__item__link {
}

.cards__item__pic-wrap {
    position: relative;
    width: 90%;
    height: 80%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
    background-color: #fff; /* Ensure image background color is white */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: white;
    box-sizing: border-box;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain; /* Adjust object-fit property as needed */
    transition: all 0.2s linear;
}

.cards__item__img:hover {
}

.cards__item__info {
    padding: 20px 30px 30px;
}

.cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

@media only screen and (max-width: 430px) {
    .cards {
        background: #555555;
     
        position: relative;
        width: 100%;
        max-height: 500px;
        height: 50vh;
        max-height: 300px;
        max-width: 85%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
    }
    .cards__item {
        margin-bottom: 2rem;
    }
}
