.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.OurSolutionsE-container {
    background: #555555;
    max-height: 1000px;
    height: 112vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 7%; /* Adjusted padding-left */
    padding-right: 50px;
   
    object-fit: contain;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
   
}


.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.OurSolutionsE__title {
    color: #fff;
    text-align: center;
}

.OurSolutionsE > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.OurSolutionsE__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.OurSolutionsE__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.OurSolutionsE__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.OurSolutionsE__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.OurSolutionsE__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.OurSolutionsE__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.OurSolutionsE__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.OurSolutionsE__item__link {
    width: 100%;
}

.OurSolutionsE__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.OurSolutionsE__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.OurSolutionsE__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.OurSolutionsE__item__img:hover {
    transform: scale(1.1);
}

.OurSolutionsE__item__info {
    padding: 20px 30px 30px;
}

.OurSolutionsE__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}



.OurSolutionsE-container > h1 {
    color:#E34B4B;
    font-size: 60px;
    text-align: center; 
}

.OurSolutionsE-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.OurSolutionsE-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.OurSolutionsE-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.OurSolutionsE-container img {
    width: 100%; /* Set width to 500px */
    height: 85%; /* Set height to 500px */
     /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 0%;
    margin-right: 0px; /* Add margin for spacing */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
}

.text-wrapper-sol-e {
    /* Styles for the text wrapper */
    width: 100%;
}

.text-wrapper-sol-e > h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: center; 
    margin-top: 0%;
}

.text-wrapper-sol-e > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-sol-e > p {
    color: #FFF;
    font-size: 32px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 50%;
    margin-top: 5%;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust minmax values as needed */
    grid-gap: 10%; /* Decrease the gap between images */
    justify-items: center;
    height: 10%;
    margin-top: 2%;
    margin-bottom: 17%;
   
}

.image-grid-item {
    position: relative;
    text-align: center; /* Center align the content */
    margin-bottom: 80%;
}

.card {
    width: 500px; /* Adjust card width as needed */
    height: 300px;
    border-radius: 10px;
  box-shadow:  #333333;
    margin-bottom: 25%;
    

}

.card-img {
    max-width: 100%;
    height:auto;
    border-top-left-radius: 10px; /* Apply rounded corners to top left and right */
    border-top-right-radius: 10px;
}

.card-description {
    background-color: #222222; /* Background color for description */
    color: #FFF; /* Text color for description */
    padding-bottom: 20px;
    
    border-bottom-left-radius: 10px; /* Apply rounded corners to bottom left and right */
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px; /* Apply rounded corners to top left and right */
    border-top-right-radius: 10px;

}

.card-description > p3 {
    color: #E34B4B;;
    font-size: 12px;
    text-align: left; 
    float: right;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 1%;
    margin-bottom: 1%;
  
}


@media screen and (max-width: 430px) { 
    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    
    .OurSolutionsE-container {
        background: #555555;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 0%; /* Adjusted padding-left */
        padding-right: 0px;
        padding: 10px;
        object-fit: contain;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
        max-height: none;
       
    }
    
    
    .homecs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    
    .OurSolutionsE__title {
        color: #fff;
        text-align: center;
    }
    
    .OurSolutionsE > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .OurSolutionsE__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 1240px;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    
    .OurSolutionsE__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .OurSolutionsE__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .OurSolutionsE__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .OurSolutionsE__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .OurSolutionsE__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .OurSolutionsE__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .OurSolutionsE__item__link {
        width: 100%;
    }
    
    .OurSolutionsE__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .OurSolutionsE__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .OurSolutionsE__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .OurSolutionsE__item__img:hover {
        transform: scale(1.1);
    }
    
    .OurSolutionsE__item__info {
        padding: 20px 30px 30px;
    }
    
    .OurSolutionsE__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    
    
    
    .OurSolutionsE-container > h1 {
        color:#E34B4B;
        font-size: 60px;
        text-align: center; 
    }
    
    .OurSolutionsE-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .OurSolutionsE-container > p {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .OurSolutionsE-btns {
        margin-top: 20px; /* Adjust the distance from the top as needed */
        margin-left: 20px; /* Adjust the distance from the left as needed */
    }
    
    .OurSolutionsE-container img {
        width: 90%; /* Set width to 500px */
        height: auto; /* Set height to 500px */
         /* Apply rounded borders */
        float: left; /* Align the image to the left */
        margin-top: 0%;
        margin-right: 0%; /* Add margin for spacing */
        margin-left: 5%;
        
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
    }
    
    .text-wrapper-sol-e {
        /* Styles for the text wrapper */
        width: 100%;
    }
    
    .text-wrapper-sol-e > h1 {
        color: #E34B4B;
        font-size: 1.5rem;
        text-align: center; 
        margin-top: 0%;
        margin-bottom: 10%;
        padding-left: 50px;
        padding-right: 50px;
    }
    
    .text-wrapper-sol-e > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapper-sol-e > p {
        color: #FFF;
        font-size: 32px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 50%;
        margin-top: 5%;
    }
    
    .image-grid {
        display: flex;
        flex-direction: column;
        
        grid-template-columns: repeat(auto-fit, minmax(50px, 0.5fr)); /* Adjust minmax values as needed */
        grid-gap: 0%; /* Decrease the gap between images */
        justify-items: center;
        height: 5%;
        margin-top: 2%;
        margin-bottom: 2%;
        
       
    }
    
    .image-grid-item {
        position: relative;
        text-align: center; /* Center align the content */
        margin-bottom: 0%;
        margin: 0 auto;
    }
    
    .card {
        width: 90%; /* Adjust card width as needed */
        height: auto;
        border-radius: 10px;
      box-shadow:  #222222;
        margin-bottom: 0%;
        margin-right: 0%;
        margin-left: 5%;
        
    
    }
    
    .card-img {
        max-width: 90%;
        margin: 0 auto;
        height:auto;
        border-top-left-radius: 10px; /* Apply rounded corners to top left and right */
        border-top-right-radius: 10px;
    }
    
    .card-description {
        background-color: #222222; /* Background color for description */
        color: #FFF; /* Text color for description */
        padding-bottom: 20px;
        width: 90%;
        margin-left: 5%;
        border-bottom-left-radius: 10px; /* Apply rounded corners to bottom left and right */
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px; /* Apply rounded corners to top left and right */
        border-top-right-radius: 10px;
        margin-bottom: 10%;
    
    }
    .card-description > p3 {
        color: #E34B4B;;
        font-size: 12px;
        text-align: left; 
        float: right;
        margin: 0 auto; 
        margin-right: 10%;
        margin-top: 0%;
        margin-bottom: 2%;
        margin-right: 2%;
      
      
    }
   
    
}

