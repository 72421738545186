.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.GovtD-container {
    position: relative;
    max-height: 700px;
    height: 75vh;
    width: 100%;
    overflow: visible;
    padding: 50px;
    padding-right: 0px;
  
    background:#555555;
   
    /* Left and right */
    z-index: 1;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}


.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.GovtD__title {
    color: #fff;
    text-align: center;
}

.GovtD > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.GovtD__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.GovtD__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.GovtD__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.GovtD__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.GovtD__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.GovtD__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.GovtD__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.GovtD__item__link {
    width: 100%;
}

.GovtD__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.GovtD__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.GovtD__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.GovtD__item__img:hover {
    transform: scale(1.1);
}

.GovtD__item__info {
    padding: 20px 30px 30px;
}

.GovtD__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.GovtD-container > h1 {
    color: white;
    font-size: 55px;
    text-align: left; 
    margin-left: 4%;
    margin-top: 2%;
}

.GovtD-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.GovtD-container > p {
    color: #FFF;
    font-size: 20px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.GovtD-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.GovtD-container img {
    width: 600px; /* Set width to 500px */
    height: 400px; /* Set height to 500px */
    border-radius: 40px; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 30px;
    margin-right: 0px; /* Add margin for spacing */
    box-shadow: 5px 5px 10px rgba(0,0, 0, 0.2); /* Add slight shadow */
    margin-left: 4%;
    }
    
    .text-wrapper-GovtD {
    /* Styles for the text wrapper */
    width: 100%;
    }
    
    .text-wrapper-GovtD > h1 {
    color: #E34b4b ;
    font-size: 40px;
    text-align: left;
    float: center;
    margin-top: 60px;
    margin-bottom: -2%;
    }
    
    .text-wrapper-GovtD > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left;
    }
    
    .text-wrapper-GovtD > p {
    color: #FFF;
    font-size: 20px;
    text-align: left;
    float: center;
    margin: 0 auto;
    margin-right: 10%;
    margin-top: 3%;
    }

    @media screen and (max-width: 430px) { 
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: -1;
        }
        
        .GovtD-container {
            position: relative;
            height: auto;
            width: 100%;
            overflow: visible;
            padding: 20px;
            padding-right: 0px;
          
            background:#555555;
           
            /* Left and right */
            z-index: 1;
            max-width: 100%; /* Set a max-width to match the navbar */
            margin: 0 auto; /* Center the hero container */
        }
        
        
        .homecs {
            padding: 4rem;
            background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
            position: relative;
        }
        
        .GovtD__title {
            color: #fff;
            text-align: center;
        }
        
        .GovtD > h2 {
            color: #FFF;
            text-align: center; 
            margin-bottom: 30px;
        }
        
        .GovtD__container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            max-width: 1240px;
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
        }
        
        .GovtD__items {
            display: flex;
            overflow-x: auto;
            padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
        }
        
        .GovtD__arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 2rem;
            color: #fff;
            cursor: pointer;
        }
        
        .GovtD__arrow-left {
            position: absolute;
            top: 50%; /* Position the arrow vertically at the center */
            left: 100px; /* Adjust the left distance as needed */
            transform: translateY(-50%);
            font-size: 2rem;
            color: #fff;
            cursor: pointer;
        }
        
        .GovtD__arrow-right {
            position: absolute;
            top: 50%; /* Position the arrow vertically at the center */
            right: 100px; /* Adjust the right distance as needed */
            transform: translateY(-50%);
            font-size: 2rem;
            color: #fff;
            cursor: pointer;
        }
        
        .GovtD__items-container {
            overflow-x: auto; /* Enable horizontal scrolling */
            max-width: 100%; /* Ensure the container takes the full width */
        }
        
        .GovtD__item {
            flex: 0 0 auto; /* Prevent cards from stretching */
            margin: 0 0.5rem; /* Adjust margin between cards */
            width: 600px; /* Set the width of each card */
            height: 400px; /* Set the height of each card */
            border-radius: 25px; /* Add rounded corners */
        }
        
        .GovtD__item__link {
            width: 100%;
        }
        
        .GovtD__item__pic-wrap {
            position: relative;
            width: 100%;
            padding-top: 67%;
            overflow: hidden;
            border-radius: 15px; /* Add rounded corners */
        }
        
        .fade-img {
            animation-name: fade-img;
            animation-duration: 2s;
        }
        
        .GovtD__item__pic-wrap::after {
            content: attr(data-category);
            position: absolute;
            bottom: 0;
            margin-left: 10px;
            padding: 6px 8px;
            max-width: calc((100%) - 60px);
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            background-color: #1f98f4;
            box-sizing: border-box;
        }
        
        .GovtD__item__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            transition: all 0.2s linear;
        }
        
        .GovtD__item__img:hover {
            transform: scale(1.1);
        }
        
        .GovtD__item__info {
            padding: 20px 30px 30px;
        }
        
        .GovtD__item__text {
            color: #252e48;
            font-size: 18px;
            line-height: 24px;
        }
        
        .GovtD-container > h1 {
            color: white;
            font-size: 55px;
            text-align: left; 
            margin-left: 4%;
            margin-top: 2%;
        }
        
        .GovtD-container > h2 {
            color: #FFF;
            font-size: 60px;
            text-align: left; 
        }
        
        .GovtD-container > p {
            color: #FFF;
            font-size: 22px;
            text-align: left; 
            margin: 0 auto; 
            margin-right: 30%;
        }
        
        .GovtD-btns {
            margin-top: 20px; /* Adjust the distance from the top as needed */
            margin-left: 20px; /* Adjust the distance from the left as needed */
        }
        
        .GovtD-container img {
            width: 95%; /* Set width to 500px */
            height: auto; /* Set height to 500px */
            border-radius: 20px; /* Apply rounded borders */
            float: left; /* Align the image to the left */
            margin-top: 30px;
            margin-right: 0px; /* Add margin for spacing */
            box-shadow: 5px 5px 10px rgba(0,0, 0, 0.2); /* Add slight shadow */
            margin-left: 0%;
            padding-right: 0px;
            margin-bottom: 3%;
            }
            
            .text-wrapper-GovtD {
            /* Styles for the text wrapper */
            width: 100%;
            }
            
            .text-wrapper-GovtD > h1 {
            color: #E34b4b ;
            font-size: 1.5rem;
            text-align: center;
            float: center;
            margin-top: 60px;
            margin-bottom: -2%;
            }
            
            .text-wrapper-GovtD > h2 {
            color: #FFF;
            font-size: 60px;
            text-align: left;
            }
            
            .text-wrapper-GovtD > p {
            color: #FFF;
            font-size: 1rem;
            text-align: left;
            float: center;
            margin: 0 auto;
            margin-right: 10%;
            margin-top: 3%;
            }


    }