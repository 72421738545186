.relay.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
.relay.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.relay.hero-container {
    height: 60vh;
    max-height: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 100px;
    overflow: hidden; /* Ensure the curved background image doesn't overflow */
    position: relative; /* Establish positioning context for the background image */
    background: linear-gradient(to right, #555555 50%, #555555 50%);
    z-index: 1;
    max-width: 1620px;
}

.relay.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5%;
    background: url('../relay-hero-j.jpeg') center center/cover no-repeat;
    /*border-radius: 0 0 18% 28%/0 0 10% 15%;*/
    border-radius:0 0 80px 80px;
    z-index: 1; /* Place the background behind other content */
    overflow: hidden;
   

}

.relay.hero-container::after {
    content: ''; /* Create a pseudo-element to overlay the shadow */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
   
}

.relay.hero-background > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    margin-top: -100px;

}

.relay.hero-background > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.relay.hero-background > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 50%;
}

.relay.hero-background > img {
    width:25%;
    margin-top:-10%;
}

.relay.hero-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
    z-index: 1;
    margin-top: 5%;
}

@media screen and (max-width: 430px) { 
    .relay.image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    .relay.homecs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    
    .relay.hero-container {
        height: 50vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 100px;
        overflow: hidden; /* Ensure the curved background image doesn't overflow */
        position: relative; /* Establish positioning context for the background image */
        background: linear-gradient(to right, #333333 50%, #333333 50%);
        z-index: 1;
    }
    
    .relay.hero-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 5%;
        padding-top: 0%;
        background: url('../relay-hero-j.jpeg') center center/cover no-repeat;
        /*border-radius: 0 0 18% 28%/0 0 10% 15%;*/
        border-radius:0 0 0px 0px;
        z-index: 1; /* Place the background behind other content */
        overflow: hidden;
        
    
    }
    
    .relay.hero-container::after {
        content: ''; /* Create a pseudo-element to overlay the shadow */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      
       
    }
    
    .relay.hero-background > h1 {
        color: #E34B4B;
        font-size: 40px;
        text-align: left; 
        margin-top: -100px;
    
    }
    
    .relay.hero-background > h2 {
        color: #FFF;
        font-size: 40px;
        text-align: left; 
    }
    
    .relay.hero-background > p {
        color: #FFF;
        font-size: 16px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 50%;
    }

    .relay.hero-background > img {
        width:45%;
        margin-top:-50%;
    }
    
    .relay.hero-btns {
        margin-top: 20px; /* Adjust the distance from the top as needed */
        margin-left: 20px; /* Adjust the distance from the left as needed */
        z-index: 1;
        margin-top: 5%;
        display: none;
    }

    

}