.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.OurSolutionsD-container {
    background: #555555;
    height: auto;
    width: 100%s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 7%; /* Adjusted padding-left */
    padding-right: 50px;
   
    object-fit: contain;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.OurSolutionsD__title {
    color: #fff;
    text-align: center;
}

.OurSolutionsD > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.OurSolutionsD__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.OurSolutionsD__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.OurSolutionsD__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.OurSolutionsD__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.OurSolutionsD__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.OurSolutionsD__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.OurSolutionsD__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.OurSolutionsD__item__link {
    width: 100%;
}

.OurSolutionsD__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.OurSolutionsD__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.OurSolutionsD__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.OurSolutionsD__item__img:hover {
    transform: scale(1.1);
}

.OurSolutionsD__item__info {
    padding: 20px 30px 30px;
}

.OurSolutionsD__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}



.OurSolutionsD-container > h1 {
    color:#E34B4B;
    font-size: 55px;
    text-align: left; 
}

.OurSolutionsD-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.OurSolutionsD-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.OurSolutionsD-btns {
    margin-top: 0%; 
    margin-left: 20px;
}

.OurSolutionsD-container img {
    width: 550px; /* Set width to 500px */
    height: auto; /* Set height to 500px */
    border-radius: 10%; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 4%;
  

    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
}

.text-wrapper-sol-d {
    /* Styles for the text wrapper */
    width: 100%;
}

.text-wrapper-sol-d > h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: left; 
    float: center;
    margin-top:3rem;
    margin-bottom:2rem;
    

}

.text-wrapper-sol-d > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-sol-d > p {
    color: #FFF;
    font-size: 20px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 50%;
    margin-top: 10%;

  
}
.hero-btns-sol {
    
    margin-left: 20px;
  }


 

@media only screen and (max-width: 430px) {

    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    
    .OurSolutionsD-container {
        background: #555555;
        height: 75vh;
        width: 100%s;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 50px;
        padding-bottom: 50px;
        padding: 50px;
       
        object-fit: contain;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
    }
    
    .homecs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    
    .OurSolutionsD__title {
        color: #fff;
        text-align: center;
    }
    
    .OurSolutionsD > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .OurSolutionsD__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 1240px;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    
    .OurSolutionsD__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .OurSolutionsD__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .OurSolutionsD__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .OurSolutionsD__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .OurSolutionsD__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .OurSolutionsD__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .OurSolutionsD__item__link {
        width: 100%;
    }
    
    .OurSolutionsD__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .OurSolutionsD__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .OurSolutionsD__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .OurSolutionsD__item__img:hover {
        transform: scale(1.1);
    }
    
    .OurSolutionsD__item__info {
        padding: 20px 30px 30px;
    }
    
    .OurSolutionsD__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    
    
    
    .OurSolutionsD-container > h1 {
        color:#E34B4B;
        font-size: 1.5rem;
        text-align: left; 
    }
    
    .OurSolutionsD-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .OurSolutionsD-container > p {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .OurSolutionsD-btns {
        margin-top: 0%; 
        margin-left: 20px;
    }
    
    .OurSolutionsD-container img {
        width: 100%; /* Set width to 500px */
        height: auto; /* Set height to 500px */
        border-radius: 10%; /* Apply rounded borders */
        float: left; /* Align the image to the left */
        margin-top: 4%;
      
        margin-bottom: 1rem;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
    }
    
    .text-wrapper-sol-d {
        /* Styles for the text wrapper */
        width: 100%;
    }
    
    .text-wrapper-sol-d > h1 {
        color: #E34B4B;
        font-size: 1.3rem;
        text-align: left; 
        float: center;
       margin: 0 auto;
       margin-bottom: 1rem;
        
    
    }
    
    .text-wrapper-sol-d > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapper-sol-d > p {
        color: #FFF;
        font-size: 1rem;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 0%;
        margin-top: 0%;
    
      
    }
    .btns-sol {
width: 10px;
height: auto;

    }
  
    .hero-btns-sol {
     
        display: none;
        margin-left: 20px;
      }
     
}