
  

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.RelayC-container {
    position: relative;
    max-height: 1000px;
    height: auto;
    width: 100%;
    overflow: visible;
    padding: 50px;
background-color: #555555;

    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
   
    z-index: 1;
}

.RelayC-container::before,
.RelayC-container::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    height: 10%;
    background: #555555;
 
}

.RelayC-container::before {
    top: 0;
    left: 0%;
    border-radius: 0 0 0 200%;
    background-color: #555555;
}

.RelayC-container::after {
    bottom: 0;
    border-radius: 0% 50% 0% 0;
    background-color: #555555;
}

.RelayC-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.RelayC__title {
    color: #fff;
    text-align: center;
}

.Relay > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.RelayC__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.RelayC__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem;
}

.RelayC__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayC__arrow-left {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayC__arrow-right {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayC__items-container {
    overflow-x: auto;
    max-width: 100%;
}

.RelayC__item {
    flex: 0 0 auto;
    margin: 0 0.5rem;
    width: 600px;
    height: 400px;
    border-radius: 25px;
}

.RelayC__item__link {
    width: 100%;
}

.RelayC__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.RelayC__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #333333;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.RelayC__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.RelayC__item__img:hover {
    transform: scale(1.1);
}

.RelayC__item__info {
    padding: 20px 30px 30px;
}

.RelayC__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.RelayC-container > h1 {
    color: white;
    font-size: 60px;
    text-align: left; 
    margin-left: 4%;
    margin-top: 2%;
}

.RelayC-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.RelayC-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.RelayC-btns {
    margin-top: 20px;
    margin-left: 20px;
}

.RelayC-container img {
    width: 20%;
    height: auto;
    border-radius: 2%;
    float: left;

    margin-right: 0px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  
}

.text-wrapper-relayb {
    width: 100%;
}

.text-wrapper-relayb > h1 {
    color: white;
    font-size: 50px;
    text-align: left; 
    float: center;
    margin-top: 60px;
}

.text-wrapper-relayb > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-relayb > p {
    color: #FFF;
    font-size: 32px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
}
.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-wrapper img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    float: left;
    margin-top: 0%;
    margin-right: 0px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 35%;
    height: 60%;
    margin-top: 30%;
    margin-left: 2%;
    background-color: #333333;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    
    border-radius: 20px; 
    box-sizing: border-box;
   
}

.text-overlay h1 {
    font-size: 36px; 
    text-align: left;
    margin-left: 10%;
    margin-top: 0%;
    margin-bottom: 5%;
    color: #E34B4B;
}

.text-overlay p {
    font-size: 18px;
    line-height: 2rem;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
}
.relayb-btns {
    margin-top: 10%; /* Add some margin to the top of the button */
    margin-left: 35%;
    width: 120%;
    
}

@media screen and (max-width: 430px){

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.RelayC-container {
    position: relative;
    height: auto;
    width: 100%;
    overflow: visible;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
background-color: #555555;

    max-width: 100%; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
   
    z-index: 1;
}

.RelayC-container::before,
.RelayC-container::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    height: 10%;
    background: #555555;
 
}

.RelayC-container::before {
    top: 0;
    left: 0%;
    border-radius: 0 0 0 200%;
    background-color: #555555;
}

.RelayC-container::after {
    bottom: 0;
    border-radius: 0% 50% 0% 0;
    background-color: #555555;
}

.RelayC-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.RelayC__title {
    color: #fff;
    text-align: center;
}

.Relay > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.RelayC__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.RelayC__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem;
}

.RelayC__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayC__arrow-left {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayC__arrow-right {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.RelayC__items-container {
    overflow-x: auto;
    max-width: 100%;
}

.RelayC__item {
    flex: 0 0 auto;
    margin: 0 0.5rem;
    width: 600px;
    height: 400px;
    border-radius: 25px;
}

.RelayC__item__link {
    width: 100%;
}

.RelayC__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.RelayC__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #333333;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.RelayC__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.RelayC__item__img:hover {
    transform: scale(1.1);
}

.RelayC__item__info {
    padding: 20px 30px 30px;
}

.RelayC__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.RelayC-container > h1 {
    color: white;
    font-size: 1.5rem;
    text-align: left; 
    margin-left: 4%;
    margin-top: 2%;
}

.RelayC-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.RelayC-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.RelayC-btns {
    margin-top: 20px;
    margin-left: 20px;
}

.RelayC-container img {
    width: 100%;
    height: auto;
    border-radius: 2%;
    float: left;

    margin-right: 0px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  
}

.text-wrapper-relayb {
    width: 100%;
}

.text-wrapper-relayb > h1 {
    color: white;
    font-size: 1.5rem;
    text-align: left; 
    float: center;
    margin-top: 60px;
}

.text-wrapper-relayb > h2 {
    color: #FFF;
    font-size: 1.5rem;
    text-align: left; 
}

.text-wrapper-relayb > p {
    color: #FFF;
    font-size: 32px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
}
.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-wrapper img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    float: left;
    margin-top: 0%;
    margin-right: 0px;
    margin-bottom: 5%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.text-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
   margin: 0 auto;
    
    padding: 20px;
    background-color: #555555;
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    
    border-radius: 20px; 
    box-sizing: border-box;
}

.text-overlay h1 {
    font-size: 1.5rem; 
    text-align: left;
    margin-left: 10%;
    margin-top: 0%;
    margin-bottom: 5%;
    color: #E34B4B;
}

.text-overlay p {
    font-size: 1rem;
    line-height: 2rem;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
}
.relayb-btns {
    margin-top: 10%; /* Add some margin to the top of the button */
    margin-left: 35%;
    width: 10%;
    display: none;
    
    
}

}