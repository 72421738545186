.footer-container-B {
    background-color: #555555;
    
    margin: 0 auto;
    width: 100%;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 2rem 0;
   
}

.footer-links-B {
    width: 85%; /* Set to full width */
    display: flex;
    justify-content: center;
    text-align: center;
    justify-content: space-between; /* Adjust spacing between child elements */
    flex-wrap: wrap;
    margin-left: 10%;
    max-width: 1630px;
}

.footer-link-wrapper-B {
    display: flex;
    flex-direction: column;
    margin: 20px;
    width: 30%; /* Adjust width to fit within the wider footer */
}

.footer-link-items-B {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%; /* Set to full width of wrapper */
    box-sizing: border-box;
    font-size: 12px;
    margin: 0 auto;
}

.footer-link-items-B h2 {
    margin-bottom: 16px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
}

.footer-link-items-B a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items-B a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form-B h2 {
    margin-bottom: 2rem;
}

.footer-input-B::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link-B {
    color: #fff;
    font-size: 24px;
}

.social-media-B {
    width: 100%;
}

.social-media-wrap-B {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 30px auto 0;
    flex-wrap: wrap;
}

.social-icons-B {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-logo-B {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights-B {
    color: #fff;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}

/* Custom Styles */
.footer-logo {
    height: 42px;
    margin-bottom: 10px;
}

.footer-heading {
    color: white;
    font-size: 16px;
}

.footer-heading span {
    color: #e34b4b;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.footer-follow-us {
    margin-left: 10%;
    margin-bottom: 1rem;
}

.footer-social-icons {
    margin-left: 1rem;
}

.footer-social-icons i {
    margin-right: 1rem;
}

.footer-contact a {
    margin-bottom: 1.5rem;
}

.footer-links h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}


@media screen and (max-width: 430px) { 
    .footer-container-B {
        background-color: #555555;
        
        margin: 0 auto;
        width: 100%;
       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 0.5rem 2rem 0rem;
    }
    
    .footer-links-B {
        width: 85%; /* Set to full width */
        display: flex;
        justify-content: center;
        text-align: center;
        justify-content: space-between; /* Adjust spacing between child elements */
        flex-wrap: wrap;
        margin: 0 auto;
    }
    
    .footer-link-wrapper-B {
        display: flex;
        flex-direction: column;
        margin: 20px;
        width: 100%; /* Adjust width to fit within the wider footer */
    }
    
    .footer-link-items-B {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        width: 100%; /* Set to full width of wrapper */
        box-sizing: border-box;
        font-size: 12px;
        margin: 0 auto;
    }
    
    .footer-link-items-B h2 {
        margin-bottom: 16px;
        font-family: 'Poppins', sans-serif;
        color: #fff;
        margin-left: 0;
    }
    
    .footer-link-items-B a {
        color: #fff;
        text-decoration: none;
        margin-bottom: 0.5rem;
    }
    
    .footer-link-items-B a:hover {
        color: #e9e9e9;
        transition: 0.3s ease-out;
    }
    
    .footer-email-form-B h2 {
        margin-bottom: 2rem;
    }
    
    .footer-input-B::placeholder {
        color: #b1b1b1;
    }
    
    /* Social Icons */
    .social-icon-link-B {
        color: #fff;
        font-size: 24px;
    }
    
    .social-media-B {
        width: 100%;
    }
    
    .social-media-wrap-B {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        max-width: 1200px;
        margin: 30px auto 0;
        flex-wrap: wrap;
    }
    
    .social-icons-B {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 240px;
    }
    
    .social-logo-B {
        color: #fff;
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
    
    .website-rights-B {
        color: #fff;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
    }
    
    /* Custom Styles */
    .footer-logo {
        height: 42px;
        margin-bottom: 10px;
    }
    
    .footer-heading {
        color: white;
        font-size: 16px;
    }
    
    .footer-heading span {
        color: #e34b4b;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }
    
    .footer-follow-us {
        margin-left: 10%;
        margin-bottom: 1rem;
    }
    
    .footer-social-icons {
        margin-left: 1rem;
    }
    
    .footer-social-icons i {
        margin-right: 1rem;
    }
    
    .footer-contact a {
        margin-bottom: 1.5rem;
    }
    
    .footer-links h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }
    

}

/* Media Queries for Responsive Design */
