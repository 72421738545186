.image-sol {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  
  .homecs-sol {
    padding: 4rem;
    position: relative;
  }
  
  .hero-container-sol {
    position: relative; /* Changed */
    max-height: 1000px;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 15%;
    padding-left: 10%;
    z-index: 0;
    
   
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
  
  
  }
  
  .hero-image { /* Added */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    max-height: 1000px;
    z-index: -1;
 
 
    
  }
  
  .hero-container-sol > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left;
    margin-top: 0%;
  }
  
  .hero-container-sol > h2 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left;
    margin-top: 0%;
  }
  
  .hero-container-sol > p {
    color: #FFF;
    font-size: 18px;
    text-align: left;
    margin: 0 auto;
    margin-right: 50%;
    margin-top:2%;
  }
  
  .hero-btns-sol {
    margin-top: 5%;
    margin-left: 20px;
  }
  
  @media screen and (max-width: 430px) {
    .image-sol {
      object-fit: cover;
      width: 100%;
      height: 50vh;
      position: absolute;
      z-index: -1;
    }
    
    .homecs-sol {
      padding: 4rem;
      position: relative;
    }
    
    .hero-container-sol {
      position: relative; /* Changed */
      height: 50vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 15%;
      padding-left: 10%;
      z-index: 0;
      
     
      max-width: 100%; /* Set a max-width to match the navbar */
      margin: 0 auto; /* Center the hero container */
    
    
    }
    
    .hero-image { /* Added */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: -1;
   
   
      
    }
    
    .hero-container-sol > h1 {
      color: #E34B4B;
      font-size: 1.5rem;
      text-align: left;
     margin-top: 15%;
     
    }
    
    .hero-container-sol > h2 {
      color: #E34B4B;
      font-size: 1.5rem;
      text-align: left;
    
    }
    
    .hero-container-sol > p {
      color: #FFF;
      font-size: 1rem;
      text-align: left;
      margin: 0 auto;
      margin-top: 10%;
      margin-right: 2%;
    
    
      
    }
    
    .hero-btns-sol {
      margin-top: 50px;
      margin-left: 100px;
     
      
    }
  }