.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.Relaye-container {
    background: #555555;
    max-height: 600px;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4%;
  
    padding-left: 5%; /* Adjusted padding-left */


    object-fit: contain;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */

}

.homecs {
    padding: 4rem;
    position: relative;
}

.Relaye__title {
    color: #fff;
    text-align: center;
}

.Relaye > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.Relaye__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.Relaye__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.Relaye__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.Relaye__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.Relaye__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.Relaye__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.Relaye__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.Relaye__item__link {
    width: 100%;
}

.Relaye__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.Relaye__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.Relaye__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.Relaye__item__img:hover {
    transform: scale(1.1);
}

.Relaye__item__info {
    padding: 20px 30px 30px;
}

.Relaye__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}


.Relaye-container > h1 {
    color:#E34B4B;
    font-size: 60px;
    text-align: left; 
}

.Relaye-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.Relaye-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.Relaye-btns {
    margin-top: 0%; 
    margin-left: 20px;
}

.Relaye-container img {
    width: 50vh; /* Set width to 500px */
    height: 40vh; /* Set height to 500px */
    border-radius: 20px; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 4%;

    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
}

.text-wrapper-relaye {
    /* Styles for the text wrapper */
    width: 100%;
}

.text-wrapper-relaye > h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: left; 
    float: center;
    margin-top: 50px;

}

.text-wrapper-relaye > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-relaye > p {
    color: #FFF;
    font-size: 32px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 50%;
    margin-top: 3%;
  
}

.hero-btns-relaye {
    margin-top: 20px;
    margin-left: 20px;
}

.img-div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   float: left;
    margin-right: 2%;
    text-align: center; 
    width: 30%;
}

.img-div > p {
    margin-top: 10%;
    margin: 0 auto;

}

@media screen and (max-width: 430px) {
    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    
    .Relaye-container {
        background: #555555;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 4%;
    
        padding-left: 5%; /* Adjusted padding-left */
        max-height:none;
    
        object-fit: contain;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
    
    }
    
    .homecs {
        padding: 4rem;
        position: relative;
    }
    
    .Relaye__title {
        color: #fff;
        text-align: center;
    }
    
    .Relaye > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .Relaye__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 1240px;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    
    .Relaye__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .Relaye__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .Relaye__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .Relaye__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .Relaye__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .Relaye__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .Relaye__item__link {
        width: 100%;
    }
    
    .Relaye__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .Relaye__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .Relaye__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .Relaye__item__img:hover {
        transform: scale(1.1);
    }
    
    .Relaye__item__info {
        padding: 20px 30px 30px;
    }
    
    .Relaye__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    
    
    .Relaye-container > h1 {
        color:#E34B4B;
        font-size: 60px;
        text-align: left; 
    }
    
    .Relaye-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .Relaye-container > p {
        color: #FFF;
        font-size: 22px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .Relaye-btns {
        margin-top: 0%; 
        margin-left: 20px;
    }
    
    .Relaye-container img {
        width: 50vh; /* Set width to 500px */
        height: 40vh; /* Set height to 500px */
        border-radius: 20px; /* Apply rounded borders */
        float: left; /* Align the image to the left */
        margin-top: 4%;
    
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Add slight shadow */
    }
    
    .text-wrapper-relaye {
        /* Styles for the text wrapper */
        width: 100%;
    }
    
    .text-wrapper-relaye > h1 {
        color: #E34B4B;
        font-size: 40px;
        text-align: left; 
        float: center;
        margin-top: 50px;
    
    }
    
    .text-wrapper-relaye > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapper-relaye > p {
        color: #FFF;
        font-size: 32px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 50%;
        margin-top: 3%;
      
    }
    
    .hero-btns-relaye {
        margin-top: 20px;
        margin-left: 20px;
    }

    .img-div {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
       float:center;
       margin-left: 2%;
        padding: 0px;
        text-align: center; 
        width: 90%;
    
    }
    .img-div > p {
        margin: 0 auto;
        margin-top: 5%;
    }
}