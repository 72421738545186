.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
.teamC { 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#555555;
    

    overflow: hidden;
    position: relative;
}
.teamC > img{
    position: relative;
    max-height: 1400px;
    height: 80vh;
    width: auto;
    max-width: 1620px;
    left: 0%;
}
.teamC > h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: center;
    padding-bottom: 2%;
    margin: 0;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.teamC > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left;
}

.teamC > p {
    color: #FFF;
    font-size: 18px;
    text-align: left;
    margin: 0 auto;
    margin-right: 50%;
}

@media screen and (max-width: 430px) { 
    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    .teamC { 
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color:#555555;
    
        overflow: hidden;
        position: relative;
    }
    .teamC > img{
        margin-top: 20%;
        position: relative;
        height: auto;
        width: 100%;
        left: 0%;
    }
    .teamC > h1 {
        color: #E34B4B;
        font-size: 1.2rem;
        text-align: center;
        padding-bottom: 0%;
        margin: 0;
        position: absolute;
        top: 10%;
        left: 50%;
        
        transform: translate(-50%, -50%);
    }
    
    .teamC > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left;
    }
    
    .teamC > p {
        color: #FFF;
        font-size: 18px;
        text-align: left;
        margin: 0 auto;
        margin-right: 50%;
    }
    

}