
.teamb-container {
   
    background: #555555;
    max-height: 800px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    object-fit: contain;
    z-index: -1;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
   
}

.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.teamb__title {

    text-align: center;
}

.teamb > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.teamb__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    background: #555555;
}

.teamb__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.teamb__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;

    cursor: pointer;
}

.teamb__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #333333;
    cursor: pointer;
}

.teamb__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;

    cursor: pointer;
}

.teamb__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.teamb__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.teamb__item__link {
    width: 100%;
}

.teamb__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.teamb__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;

    background-color: #1f98f4;
    box-sizing: border-box;
}

.teamb__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.teamb__item__img:hover {
    transform: scale(1.1);
}

.teamb__item__info {
    padding: 20px 30px 30px;
}

.teamb__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}



.teamb-container > h1 {
    color:#E34B4B;
    font-size: 60px;
    text-align: left; 
    margin-top: -100%;
    z-index: 1;
}

.teamb-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.teamb-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.teamb-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.teamb-container img {
    width: 100%; /* Set width to 500px */
    height: 30%; /* Set height to 500px */
    border-radius: 0%; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 0px;
    margin-right: 0px; /* Add margin for spacing */

    z-index: -1;
}

.text-wrapper-team-b {
    /* Styles for the text wrapper */
    width: 100%;
    height: 100%;
    background-color: #333333;
}

.text-wrapper-team-b > h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: left; 
    float: center;
    margin-left: 25%;
    margin-top: -2%;
    margin-bottom: 2%;
}

.text-wrapper-team-b > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-team-b > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    float: center;
    margin-right: 10%;
    margin-top: 0%;
    margin-left: 5%;
}


.text-wrapper-team-b > p3 {
    color: #E34B4B;;
    font-size: 18px;
    text-align: left; 
    float: right;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
}

.text-wrapper-team-b > img{

    float: center;
     height: auto;
     margin-top: 2%;
     background-color:#333333;
     width:80%;
     margin-left:10%;
     border-radius:40px;
}

.custom-h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: left;
}

.custom-p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
}

.custom-p3 {
    color: #E34B4B;;
    font-size: 24px;
    text-align: left; 
    float: right;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
    font-weight: bold;
}



@media screen and (max-width: 430px) {
  
.teamb-container {
   
    background: #333333;
  
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    object-fit: contain;
    z-index: -1;
    max-width: 100%; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
   
}

.homecs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.teamb__title {

    text-align: center;
}

.teamb > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.teamb__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1240px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    background: #333333;
}

.teamb__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.teamb__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;

    cursor: pointer;
}

.teamb__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #333333;
    cursor: pointer;
}

.teamb__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;

    cursor: pointer;
}

.teamb__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.teamb__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.teamb__item__link {
    width: 100%;
}

.teamb__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.teamb__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;

    background-color: #1f98f4;
    box-sizing: border-box;
}

.teamb__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.teamb__item__img:hover {
    transform: scale(1.1);
}

.teamb__item__info {
    padding: 20px 30px 30px;
}

.teamb__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}



.teamb-container > h1 {
    color:#E34B4B;
    font-size: 60px;
    text-align: left; 
    margin-top: -100%;
    z-index: 1;
}

.teamb-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.teamb-container > p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.teamb-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.teamb-container img {
    width: 100%; /* Set width to 500px */
    height: 30%; /* Set height to 500px */
    border-radius: 0%; /* Apply rounded borders */
    float: left; /* Align the image to the left */
    margin-top: 0px;
    margin-right: 0px; /* Add margin for spacing */

    z-index: -1;
}

.text-wrapper-team-b {
    /* Styles for the text wrapper */
    width: 100%;
    height: 100%;
    background-color: #333333;
}

.text-wrapper-team-b > h1 {
    color: #E34B4B;
    font-size: 1.5rem;
    text-align: left; 
    float: center;
    margin-left: 25%;
    margin-top: -2%;
    margin-bottom: 2%;
}

.text-wrapper-team-b > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapper-team-b > p {
    color: #FFF;
    font-size: 1rem;
    text-align: left; 
    float: center;
    margin-right: 10%;
    margin-top: 0%;
    margin-left: 5%;
}


.text-wrapper-team-b > p3 {
    color: #E34B4B;;
    font-size: 18px;
    text-align: left; 
    float: right;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
}

.text-wrapper-team-b > img{

    float: center;
     height: auto;
     margin: 0 auto;
     margin-top: 2%;
     background-color:#333333;
     width:95%;
     margin-left:2%;
     border-radius: 20px;

     padding-bottom: 0px;
     margin-bottom: 20px;
}

.custom-h1 {
    color: #E34B4B;
    font-size: 40px;
    text-align: left;
}

.custom-p {
    color: #FFF;
    font-size: 22px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
}

.custom-p3 {
    color: #E34B4B;;
    font-size: 24px;
    text-align: left; 
    float: right;
    margin: 0 auto; 
    margin-right: 10%;
    margin-top: 3%;
    font-weight: bold;
}


}