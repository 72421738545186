.image {
    float: left;
    margin-left: 30%;
}

.homed-container {
    background: #555555;

    min-height: 800px;
    max-height: 900px;
    height: 60vh;
    width: 100%; /* Remove this to inherit width */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */


}

/* Add red rings */
.homed-container::before {
    content: "";
    position: absolute;
    top: 2%; /* Adjust as needed */
    left: -8%; /* Adjust as needed * initally -8% */
    width: 400px; /* Adjust size as needed */
    height: 400px; /* Adjust size as needed */
    background-image: radial-gradient(circle, transparent 45%, #E35F5F 70%, #E35F5F 80%, #E35F5F 80%, #E35F5F 80%);
    border-radius: 50%;
}

.homed-container::after {
    content: "";
    position: absolute;
    top: 1%; /* Adjust as needed */
    left: 55%; /* Adjust as needed * initially 55% */
    width: 1080px; /* Adjust size as needed */
    height: 1080px; /* Adjust size as needed */
    background-image: radial-gradient(circle, transparent 55%, #E35F5F 70%, #E35F5F 80%, #E35F5F 80%, #E35F5F 80%);
    border-radius: 50%; 
 
}

.homeds {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.homed__title {
    color: #fff;
    text-align: center;
}

.homed > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.homed__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 80%;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.homed__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.homed__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homed__arrow-left {
    position: absolute;
    top: 50%;
    left: 100px; /* Adjust as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homed__arrow-right {
    position: absolute;
    top: 50%;
    right: 100px; /* Adjust as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homed__items-container {
    overflow-x: auto;
    max-width: 100%;
}

.homed__item {
    flex: 0 0 auto;
    margin: 0 0.5rem;
    width: 600px;
    height: 400px;
    border-radius: 25px;
}

.homed__item__link {
    width: 100%;
}

.homed__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.homed__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.homed__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.homed__item__img:hover {
    transform: scale(1.1);
}

.homed__item__info {
    padding: 20px 30px 30px;
}

.homed__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.homed-container > h1 {
    color: #E34B4B;
    font-size: 48px;
    margin: 0 auto; /* Adjust as needed */
    /* left: 50%; Remove this */
}

.homed-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; /* Adjust as needed */
}

.homed-container > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; /* Adjust as needed */
    margin: 0 auto; /* Adjust as needed */
    /* left: 50% Remove this */
}

.homed-container > h3 {
    color: #FFF;
    font-size: 1.2rem;
    text-align: left; 
    margin-left: 42%; /* Adjust as needed */
}

.homed-btns {
    margin-top: 20px;
    margin-left: 20px;
}

.homed-container img {
    width: 500px;
    height: 500px;
    border-radius: 80px;
    float: left;
    margin-top: 30px;
    margin-left: 0%;
  
   
}

.text-wrapperD {
    width: 100%;
    height: 100vh;
}

.text-wrapperD > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left;
    margin-top: 100px;
}

.text-wrapperD > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left;
}

.text-wrapperD > p {
    color: #FFF;
    font-size: 18px;
    text-align: left;
    margin: 0 auto;
    margin-right: 10%;
}

.text-wrapperD > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left;
}

.learn-moreD {
    position: relative;
    color: black;
    margin-top: 5%;
    text-align: right;
    font-size: 16px;
}

.learn-moreD::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px;
    height: 1px;
    background-color: white;
}

.learn-moreD .fas.fa-arrow-right {
    margin-left: 5px;
}

.text-wrapperD {
    position: relative;
    overflow: hidden;
}

.text-wrapperD img {
    float: left;
    width: 650px;
    height: 550px;
    margin-left: 10%;
}

.text-wrapper-2D {
    width: 100%;
}

.text-wrapper-2D > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left;
    margin-top: 100px;
}

.text-wrapper-2D > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left;
}

.text-wrapper-2D > p {
    color: #FFF;
    font-size: 18px;
    text-align: left;
    margin: 0 auto;
    margin-right: 10%;
}

.text-wrapper-2D > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left;
}

.content-wrapperD {
    background-color: #E35F5F;
    padding: 50px;
    border-radius: 80px;
 
    margin-left: 43%;
    margin-top: 70px;
    z-index: 1;
    position: absolute;
    width: 650px;
    height: 550px;
    overflow: hidden;
    
}

.content-wrapperD > h3 {
    color: white;
    font-size: 2rem;
    margin-bottom: 1.1rem;
    font-weight: bold;
}

.content-wrapperD > p {
    color: white;
    font-size: 1.2rem;
    line-height: 2.5rem;
}

.learn-moreD {
    position: relative;
    color: white;
    margin-top: 20px;
    text-align: right;
    font-weight: bold;
}

.learn-moreD::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px;
    height: 1px;
    background-color: white;
    font-weight: bold;
}

.learn-moreD > p {
    font-weight: italic;
}

.learn-moreD .fas.fa-arrow-right {
    margin-left: 5px;
}

.text-wrapper-2D {
    position: relative;
}

.imageD {
    float: left;
    margin-right: 100px;
}
@media only screen and (max-width: 430px)  {

    .image {
        float: left;
        margin-left: 30%;
    }
  
    .homed-container {
        background: #555555;
      
        height: auto;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        object-fit: contain;
        position: relative; 
        overflow: hidden;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 0px;
        min-height: 200px;

        
    }
  
    .homed-container::before {
        content: "";
        position: absolute;
        top: 0%; /* Adjust as needed */
        left: 0%; /* Adjust as needed * initally -8% */
        width: 0vh; /* Adjust size as needed */
        height: 0vh; /* Adjust size as needed */
        background-image: radial-gradient(circle, transparent );
        z-index: -1;
  
    }
    
    .homed-container::after {
        content: "";
        position: absolute;
        top: 0%; /* Adjust as needed */
        left: 0%; /* Adjust as needed * initially 55% */
        width: 0vh; /* Adjust size as needed */
        height: 0vh; /* Adjust size as needed */
        background-image: radial-gradient(circle, transparent );

        z-index: -1;
     
    }
  
    .homeds {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
  
    .homed__title {
        color: #fff;
        text-align: center;
    }
  
    .homed > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
  
    .homed__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 80%;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
  
    .homed__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
  
    .homed__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
  
    .homed__arrow-left {
        position: absolute;
        top: 50%;
        left: 100px; /* Adjust as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
  
    .homed__arrow-right {
        position: absolute;
        top: 50%;
        right: 100px; /* Adjust as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
  
    .homed__items-container {
        overflow-x: auto;
        max-width: 100%;
    }
  
    .homed__item {
        flex: 0 0 auto;
        margin: 0 0.5rem;
        width: 600px;
        height: 400px;
        border-radius: 25px;
    }
  
    .homed__item__link {
        width: 100%;
    }
  
    .homed__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px;
    }
  
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
  
    .homed__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
  
    .homed__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
  
    .homed__item__img:hover {
        transform: scale(1.1);
    }
  
    .homed__item__info {
        padding: 20px 30px 30px;
    }
  
    .homed__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
  
    .homed-container > h1 {
        color: #E34B4B;
        font-size: 22px;
        margin: 0 auto; /* Adjust as needed */
    }
  
    .homed-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; /* Adjust as needed */
    }
  
    .homed-container > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; /* Adjust as needed */
        margin: 0 auto; /* Adjust as needed */
    }
  
    .homed-container > h3 {
        color: #FFF;
        font-size: 1rem;
        text-align: left; 
        margin: 0 auto;
    }
  
    .homed-btns {
        margin-top: 20px;
        margin-left: 20px;
    }
  
    .homed-container img {
        width: 100%;
        height: auto;
        border-radius: 30px;
        float: left;
        margin-top: 30px;
        margin-right: 0px;
        margin-left: 0%;
        
    }
  
    .text-wrapperD {
        width: 100%;
        height:auto;
     
    }
  
    .text-wrapperD > h1 {
        color: #E34B4B;
        font-size: 16px;
        text-align: left;
        margin-top: 100px;
    }
  
    .text-wrapperD > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left;
    }
  
    .text-wrapperD > p {
        color: #FFF;
        font-size: 18px;
        text-align: left;
        margin: 0 auto;
        margin-right: 10%;
    }
  
    .text-wrapperD > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left;
    }
  
    .learn-moreD {
        position: relative;
        color: black;
        margin-top: 5%;
        text-align: right;
        font-size: 12px;
    }
  
    .learn-moreD::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 80px;
        height: 1px;
        background-color: white;
    }
  
    .learn-moreD .fas.fa-arrow-right {
        margin-left: 5px;
    }
  
 
  
  
  
    .text-wrapper-2D {
        width: 100%;
    }
  
    .text-wrapper-2D > h1 {
        color: #E34B4B;
        font-size: 16px;
        text-align: left;
        margin-top: 100px;
    }
  
    .text-wrapper-2D > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left;
    }
  
    .text-wrapper-2D > p {
        color: #FFF;
        font-size: 18px;
        text-align: left;
        margin: 0 auto;
        margin-right: 10%;
    }
  
    .text-wrapper-2D > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left;
    }
  
    .content-wrapperD {
        background-color: #555555;

       
      
        z-index: 1;
    
        width: 100%;
        height: auto;
        overflow: hidden;
        float: left;
        margin: 0 auto;
        position: relative;
        margin-bottom: 0px;
        
    }
  
    .content-wrapperD > h3 {
        color: #E34B4B;
        font-size: 1.2rem;
        margin-bottom: 1.1rem;
        font-weight: bold;
    }
  
    .content-wrapperD > p {
        color: white;
        font-size: 0.8rem;
        line-height: 2vh;
    }
  
    .learn-moreD {
        position: relative;
        color: #E34B4B;
        margin-top: 10px;
        text-align: right;
        font-weight: bold;
    }
  
    .learn-moreD::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 60px;
        height: 1px;
        background-color: #E34B4B;
        font-weight: bold;
    }
  
    .learn-moreD > p {
        font-weight: italic;
        font-size: 10px;
    }
  
    .learn-moreD .fas.fa-arrow-right {
        margin-left: 5px;
    }
  
    .text-wrapper-2D {
        position: relative;
    }
  
    .imageD {
        float: left;
        margin-right: 100px;
    }
  }
  