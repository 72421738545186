.imageG {
    float: left;
    margin-right: 100px;
}
.imageG-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #555555; /* Adjust the color and opacity as needed */
    z-index: 1;
}
.homeG-container {
    background:  #555555;
    min-height: 800px;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; /* Necessary for absolute positioning of rings */
    overflow: hidden;
    max-width: 1632px; /* Set a max-width to match the navbar */
    margin: 0 auto; /* Center the hero container */
}

.homeGs {
    padding: 4rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    position: relative;
}

.homeG__title {
    color: #fff;
    text-align: center;
}

.homeG > h2 {
    color: #FFF;
    text-align: center; 
    margin-bottom: 30px;
}

.homeG__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 80%;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.homeG__items {
    display: flex;
    overflow-x: auto;
    padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
}

.homeG__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeG__arrow-left {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    left: 100px; /* Adjust the left distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeG__arrow-right {
    position: absolute;
    top: 50%; /* Position the arrow vertically at the center */
    right: 100px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.homeG__items-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width */
}

.homeG__item {
    flex: 0 0 auto; /* Prevent cards from stretching */
    margin: 0 0.5rem; /* Adjust margin between cards */
    width: 600px; /* Set the width of each card */
    height: 400px; /* Set the height of each card */
    border-radius: 25px; /* Add rounded corners */
}

.homeG__item__link {
    width: 100%;
}

.homeG__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 15px; /* Add rounded corners */
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.homeG__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.homeG__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.homeG__item__img:hover {
    transform: scale(1.1);
}

.homeG__item__info {
    padding: 20px 30px 30px;
}

.homeG__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

.homeG-container > h1 {
    color: #E34B4B;
    font-size: 48px;
    margin: 0 auto; 
    margin-right: 30%;
}

.homeG-container > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.homeG-container > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    margin: 0 auto; 
    margin-right: 30%;
}

.homeG-container > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.homeG-btns {
    margin-top: 20px; /* Adjust the distance from the top as needed */
    margin-left: 20px; /* Adjust the distance from the left as needed */
}

.homeG-container > img {
    width: 100%;
     height: 100%;
     overflow: hidden; 
   z-index: 0;
   margin-top: 30px;
   }


.text-wrapperG {
    /* Styles for the text wrapper */
    width: 100%;
position: absolute;
 top: 0%;
  left: 0;
   width: 100%;
   height: 100%;
    
}

.text-wrapperG > h1 {
    color: white;
    font-size: 80px;
    text-align: left; 
    float: center;
    margin-top: 100px;
    font-weight: bold;
margin-left: 10%;
 position: relative; 

 margin-top: 5%;
  z-index: 1; 
 
}

.text-wrapperG > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapperG > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
    position: relative;
    overflow: hidden;
}

.text-wrapperG > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.content-wrapperG {
    background-color: none; /* Red background */
    padding: 10px; /* Add padding for spacing */
    border-radius: 30px; /* Add rounded corners */
    margin-left: 250px;
    margin-right: 100px;
    margin-top: 100px;
    z-index: 1;
    position: relative;
}

.content-wrapperG > h1 {
    font-size: 28px;

}

.learn-moreG {
    position: relative;
    color: black;
    margin-top: 5%;
    text-align: right;
}

.learn-moreG::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px; /* Width of the line */
    height: 1px; /* Height of the line */
    background-color: white;
}

.learn-moreG .fas.fa-arrow-right {
    margin-left: 5px;
}


.text-wrapperG img {
    float: left;/* Adjust the width as needed */
    position: relative;
   float: center; margin-left: '';
    width: 100px;
     height: 100px;
      margin-left: 40%;
       border-radius: 10%;
        top:-12%;
        z-index: 1;
}

.text-wrapperG-2 {
    /* Styles for the text wrapper */
    width: 100%;
}

.text-wrapperG-2 > h1 {
    color: #E34B4B;
    font-size: 60px;
    text-align: left; 
    float: center;
    margin-top: 100px;
}

.text-wrapperG-2 > h2 {
    color: #FFF;
    font-size: 60px;
    text-align: left; 
}

.text-wrapperG-2 > p {
    color: #FFF;
    font-size: 18px;
    text-align: left; 
    float: center;
    margin: 0 auto; 
    margin-right: 10%;
}

.text-wrapperG-2 > h3 {
    color: #FFF;
    font-size: 24px;
    text-align: left; 
    margin: 0 auto; 
}

.content-wrapperG {
 
    padding: 50px; /* Add padding for spacing */
    border-radius: 70px; /* Add rounded corners */
    margin-left: 550px;
    margin-right: 500px;
    margin-top: 70px;
    
  


 width: 70%;
  height: 50vh;
   background: none;
    float: left;
    margin-left: 5%; 
    padding: 20px;
     position: absolute;
      bottom: 10%;
       z-index: 1;
        top: 20%;
}

.content-wrapperG > h1 {
   color: white; z-index: 1;
   margin-top:10%;
}
.learn-moreG {
    position: relative;
    color: white;
    margin-top: 10%;
    text-align: right;
    font-size: 20px;
}

.learn-moreG::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 80px; /* Width of the line */
    height: 1px; /* Height of the line */
    background-color: white;
}

.learn-moreG .fas.fa-arrow-right {
    margin-left: 5px;
}

.text-wrapperG-2 {
    position: relative;
}

.imageG-overlay {
position: absolute;
 top: 0; 
 left: 0;
  width: 100%;
   height: 100%;
    background-color: rgba(111, 80, 80, 0.7); z-index: 1;
    margin-top: 1.8%;
    overflow: hidden;
}
.imageG-overlay > img {
 width: 100%;
  height: 100%;
  overflow: hidden; 
z-index: 0;
}
.division-line{
    width: 2px;
     height: 200px;
      background-color: #ffffff;
       position: absolute;
        left: 37%;
         top: 16%;
          transform: translate(-50%, -50%);
           z-index: 1;
}
.bottom-line{
   width: 100%;
    height: 2px;
     background-color: #ffffff;
      position: absolute; top: 30%;
       z-index: 1;
}

@media only screen and (max-width: 430px)  { 


    .imageG {
        float: left;
        margin-right: 100px;
    }
    .imageG-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #333333; /* Adjust the color and opacity as needed */
        z-index: 1;
    }
    .homeG-container {
        background: #555555;
      
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        position: relative; 
        overflow: hidden;
        max-width: 100%; /* Set a max-width to match the navbar */
        margin: 0 auto; /* Center the hero container */
        padding: 10px;
        max-width: none;
        min-height: 200px;
    }
    
    .homeGs {
        padding: 4rem;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        position: relative;
    }
    
    .homeG__title {
        color: #fff;
        text-align: center;
    }
    
    .homeG > h2 {
        color: #FFF;
        text-align: center; 
        margin-bottom: 30px;
    }
    
    .homeG__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 80%;
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    
    .homeG__items {
        display: flex;
        overflow-x: auto;
        padding: 1rem; /* Add padding to prevent the arrows from overlapping with cards */
    }
    
    .homeG__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeG__arrow-left {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        left: 100px; /* Adjust the left distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeG__arrow-right {
        position: absolute;
        top: 50%; /* Position the arrow vertically at the center */
        right: 100px; /* Adjust the right distance as needed */
        transform: translateY(-50%);
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
    }
    
    .homeG__items-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the container takes the full width */
    }
    
    .homeG__item {
        flex: 0 0 auto; /* Prevent cards from stretching */
        margin: 0 0.5rem; /* Adjust margin between cards */
        width: 600px; /* Set the width of each card */
        height: 400px; /* Set the height of each card */
        border-radius: 25px; /* Add rounded corners */
    }
    
    .homeG__item__link {
        width: 100%;
    }
    
    .homeG__item__pic-wrap {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        border-radius: 15px; /* Add rounded corners */
    }
    
    .fade-img {
        animation-name: fade-img;
        animation-duration: 2s;
    }
    
    .homeG__item__pic-wrap::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
    }
    
    .homeG__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;
    }
    
    .homeG__item__img:hover {
        transform: scale(1.1);
    }
    
    .homeG__item__info {
        padding: 20px 30px 30px;
    }
    
    .homeG__item__text {
        color: #252e48;
        font-size: 18px;
        line-height: 24px;
    }
    
    .homeG-container > h1 {
        color: #E34B4B;
        font-size: 48px;
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .homeG-container > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .homeG-container > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        margin: 0 auto; 
        margin-right: 30%;
    }
    
    .homeG-container > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .homeG-btns {
        margin-top: 20px; /* Adjust the distance from the top as needed */
        margin-left: 20px; /* Adjust the distance from the left as needed */
    }
    
    .homeG-container > img {
        width: 100%;
         height: 100%;
         overflow: hidden; 
       z-index: 0;
       margin-top: 30px;
       }
    
    
    .text-wrapperG {
        /* Styles for the text wrapper */
        width: 100%;
    position: absolute;
     top: 0%;
      left: 0;
       width: 100%;
       height: 100%;
        
    }
    
    .text-wrapperG > h1 {
        color: white;
        font-size: 22px;
        text-align: left; 
        float: center;
        margin-top: 100px;
        font-weight: bold;
    margin-left: 10%;
     position: relative; 
    
     margin-top: 17%;
      z-index: 1; 
     
    }
    
    .text-wrapperG > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapperG > p {
        color: #FFF;
        font-size: 12px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 10%;
        position: relative;
        overflow: hidden;
    }
    
    .text-wrapperG > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .content-wrapperG {
        background-color: none; /* Red background */
        padding: 10px; /* Add padding for spacing */
        border-radius: 30px; /* Add rounded corners */
        margin-left: 250px;
        margin-right: 100px;
        margin-top: 100px;
        z-index: 1;
        position: relative;
    }
    
    .content-wrapperG > h1 {
        font-size: 16px;
    
    }
    
    .learn-moreG {
        position: relative;
        color: black;
        margin-top: 5%;
        text-align: right;
    }
    
    .learn-moreG::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 80px; /* Width of the line */
        height: 1px; /* Height of the line */
        background-color: white;
    }
    
    .learn-moreG .fas.fa-arrow-right {
        margin-left: 5px;
    }
    
    
    .text-wrapperG img {
        float: left;/* Adjust the width as needed */
        position: relative;
       float: center; margin-left: '';
        width: 4vh;
         height: 4vh;
          margin-left: 38%;
           border-radius: 10%;
            top:-8%;
            z-index: 1;
    }
    
    .text-wrapperG-2 {
        /* Styles for the text wrapper */
        width: 100%;
    }
    
    .text-wrapperG-2 > h1 {
        color: #E34B4B;
        font-size: 60px;
        text-align: left; 
        float: center;
        margin-top: 100px;
    }
    
    .text-wrapperG-2 > h2 {
        color: #FFF;
        font-size: 60px;
        text-align: left; 
    }
    
    .text-wrapperG-2 > p {
        color: #FFF;
        font-size: 18px;
        text-align: left; 
        float: center;
        margin: 0 auto; 
        margin-right: 10%;
    }
    
    .text-wrapperG-2 > h3 {
        color: #FFF;
        font-size: 24px;
        text-align: left; 
        margin: 0 auto; 
    }
    
    .content-wrapperG {
     
        padding: 50px; /* Add padding for spacing */
        border-radius: 70px; /* Add rounded corners */
        margin-left: 550px;
        margin-right: 500px;
        margin-top: 70px;
        
      
    
    
     width: 70%;
      height: 50vh;
       background: none;
        float: left;
        margin-left: 5%; 
        padding: 20px;
         position: absolute;
          bottom: 10%;
           z-index: 1;
            top: 20%;
    }
    
    .content-wrapperG > h1 {
       color: white;
        z-index: 1;
       margin-top:0%;
       font-size: 12px;
    }
    .learn-moreG {
        position: relative;
        color: white;
        margin-top: 10%;
        text-align: right;
        font-size: 20px;
    }
    
    .learn-moreG::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 80px; /* Width of the line */
        height: 1px; /* Height of the line */
        background-color: white;
    }
    
    .learn-moreG .fas.fa-arrow-right {
        margin-left: 5px;
    }
    
    .text-wrapperG-2 {
        position: relative;
    }
    
    .imageG-overlay {
    position: absolute;
     top: 0; 
     left: 0;
      width: 95.7%;
       height: 91.4%;
        background-color: rgba(111, 80, 80, 0.7);
         z-index: 1;
        margin-top: 9%;
        margin-left: 2%;
    }
    .imageG-overlay > img {
     width: 100%;
      height: 100%;
      overflow: hidden; 

    z-index: 0;
    }
    .division-line{
        width: 2px;
         height: 5vh;
          background-color: #ffffff;
           position: absolute;
            left: 33%;
             margin-top: 10%;
              transform: translate(-50%, -50%);
               z-index: 1;
    }
    .bottom-line{
       width: 90%;
        height: 2px;
         background-color: #ffffff;
          position: absolute; 
          top: 40%;
           z-index: 1;
           margin-left: 5%;
    }
    


}